/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { i18n } from '../../utils/translates/i18n';
import { getCallMessages, sendCallMessages } from '../../services/CallsService';
import { FormateDate } from '../../utils/CustomFormatations';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';

export default function ReadCall() {
	const token = sessionStorage.getItem('token');
	const idCall = sessionStorage.getItem('idCall');

	const DEFAULTDATA = {
		callId: idCall,
		message: '',
	};

	const [call, setCall] = useState([]);
	const [data, setData] = useState(DEFAULTDATA);

	const onInputChange = (field, value) => setData(prevState => ({ ...prevState, [field]: value }));

	const [msgAlertShow, setMsgAlertShow] = useState(false);
	const [erroMsgAlertShow, setErroMsgAlertShow] = useState(false);

	const doGetCallMessages = async () => {
		const success = await getCallMessages(idCall, token);
		if (success.status) {
			setCall(success.call);
			document.title = `${i18n.t('subject')}: ${success.call?.subject} | ${process.env.REACT_APP_NAME}`;
		}
	};

	const sendReply = async () => {
		const success = await sendCallMessages(data, token);
		if (success.status) {
			doGetCallMessages();
			onInputChange('message', '');
			setMsgAlertShow(true);
			setErroMsgAlertShow(false);
		} else {
			setMsgAlertShow(false);
			setErroMsgAlertShow(true);
		}
	};

	useEffect(() => {
		doGetCallMessages();
	}, [idCall]);

	return (
		<>
			<Menu />
			<main className='content'>
				<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
					<div className='d-block mb-4 mb-md-0'>
						<h2 className='h4'>{i18n.t('calls')}</h2>
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<div class='messages_box_area'>
							<div className='messages_chat'>
								<div className='white_box bg-light'>
									<div className='row'>
										<div className='col-md-6 border-end border-primary'>
											<div className='border-0 d-sm-flex d-block'>
												<div className='mb-4'>
													<p className='fs-4 text-dark'>
														{i18n.t('user')}: <span className=' fw-bold'>{call?.user?.name}</span>
													</p>
													<p className='fs-4 text-dark'>
														{i18n.t('subject')}: <span className=' fw-bold'>{call?.subject?.toUpperCase()}</span>
													</p>
													<span className='ms-auto'>{FormateDate(call?.createdAt)} </span>
												</div>
											</div>
											<label>{i18n.t('answer')}</label>
											<div className='input-group mb-5'>
												<textarea
													className='form-control'
													rows={4}
													onChange={e => onInputChange('message', e.target.value)}
													value={data.message}></textarea>
												<button className='btn btn-primary' onClick={sendReply}>
													<i className='fas fa-paper-plane'></i> {i18n.t('submit')}
												</button>
											</div>

											{msgAlertShow && (
												<div className='alert alert-success alert-dismissible my-2'>
													<span className='fw-bold'>{i18n.t('congratulations')}!</span> {i18n.t('messageSent')}
													<button className='btn btn-close' onClick={() => setMsgAlertShow(false)}></button>
												</div>
											)}
											{erroMsgAlertShow && (
												<div className='alert alert-danger alert-dismissible my-2'>
													<strong>{i18n.t('warning')}!</strong> {i18n.t('messageNotSent')}.
													<button className='btn btn-close' onClick={() => setErroMsgAlertShow(false)}></button>
												</div>
											)}
										</div>
										<div className='col-md-6'>
											{call?.messages?.map((msg, i) => (
												<div className={`single_message_chat ${msg.admId && 'sender_message'} `} key={i}>
													<div className='message_pre_left mb-2'>
														{msg.admId && (
															<div className='messges_info'>
																<h4>{process.env.REACT_APP_NAME}</h4>
																<p>{FormateDate(msg.createdAt, true, true)}</p>
															</div>
														)}
														<div className='message_preview_thumb'>
															<img
																src={
																	msg.admId
																		? '/logos/favicon.png'
																		: `${process.env.REACT_APP_API_URL}/images/${call.user?.photo || 'user.png'}`
																}
																width={'auto'}
																height={50}
																alt='user'
																className='bg-grey-light'
																crossOrigin='anonymous'
															/>
														</div>
														{!msg.admId && (
															<div className='messges_info'>
																<h4>{call.user?.nick?.toUpperCase()}</h4>
																<p>{FormateDate(msg.createdAt, true, true)}</p>
															</div>
														)}
													</div>
													<div className={`message_content_view ${!msg.admId && 'red_border'} shadow`}>
														<p style={{ whiteSpace: 'pre-line' }} className={`${msg.admId && 'text-dark'}`}>
															{msg.message}
														</p>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</main>
		</>
	);
}
