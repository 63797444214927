/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Pagination from '../../utils/pagination/Pagination';
import { getWithdrawals, payWithdraw } from '../../services/WithdrawalsService';
// import Toast from '../../components/Toast/Toast';
import { i18n } from '../../utils/translates/i18n';
import WithdrawRow from '../Withdrawals/WithdrawRow';
import WithdrawModal from '../Withdrawals/WithdrawModal/WithdrawModal.js';
import Toast from '../../components/Toast/Toast';

function Withdrawals() {
	const defaultLocation = useLocation();
	const history = useHistory();
	const [notification, setNotification] = useState({ type: '', text: '' });

	const [withdrawals, setWithdrawals] = useState([]);
	const [editWithdraw, setEditWithdraw] = useState([]);
	const [totalSelected, setTotalSelected] = useState(0);
	// const [notification, setNotification] = useState({ type: '', text: '' });

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [coin, setCoin] = useState('btc');
	const [search, setSearch] = useState('');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);

	const doGetWithdrawals = async () => {
		const success = await getWithdrawals(token, page, lim, search);
		if (success.status) {
			setWithdrawals(success.list.rows);
			setCount(success.list.count);
		}
	};

	useEffect(() => {
		doGetWithdrawals();
	}, [token, page, lim, search]);

	function getPage(location) {
		if (!location) location = defaultLocation;
		return new URLSearchParams(location.search).get('page');
	}

	useEffect(() => {
		return history.listen(location => {
			setPage(getPage(location));
		});
	}, [history]);
	// FIM PARA PAGINAÇÃO

	const chooseWithdraw = (id, amount) => {
		if (!id) return;
		if (editWithdraw.includes(id)) {
			setEditWithdraw(p => p.filter(f => f !== id));
			setTotalSelected(t => t - amount);
		} else {
			setEditWithdraw(p => [...p, id]);
			setTotalSelected(t => t + amount);
		}
	};

	const doPayWithdraw = async () => {
		const success = await payWithdraw(token, editWithdraw, coin);
		if (success.status) doGetWithdrawals();
		else setNotification({ type: 'error', text: success.error });
	};

	return (
		<React.Fragment>
			<Menu />
			<main className='content'>
				<>
					<div className='container-fluid'>
						<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
							<div className='d-block mb-4 mb-md-0'>
								<h2 className='h4'>{i18n.t('withdrawals')}</h2>
							</div>
						</div>
						<Pagination count={count} pageSize={lim}>
							<div className='align-items-center'>
								<div className='alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center'>
									<i className='fas fa-search'></i>
								</div>
							</div>
							<div className='input-group me-3'>
								<input
									className='form-control'
									value={search}
									onChange={e => setSearch(e.target.value)}
									placeholder={`${i18n.t('search')} ${i18n.t('by')} ${i18n.t('nick')}`}
								/>
							</div>
							<div className='input-group me-3 w-50'>
								<select className='form-select' value={coin} onChange={e => setCoin(e.target.value)}>
									<option value='btc'>BTC</option>
								</select>
							</div>
							<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
								<option className='bg-light' value='5'>
									5 {i18n.t('registers')}
								</option>
								<option className='bg-light' value='10'>
									10 {i18n.t('registers')}
								</option>
								<option className='bg-light' value='25'>
									25 {i18n.t('registers')}
								</option>
								<option className='bg-light' value='50'>
									50 {i18n.t('registers')}
								</option>
								<option className='bg-light' value='100'>
									100 {i18n.t('registers')}
								</option>
							</select>
						</Pagination>
						{editWithdraw.length ? (
							<button className='btn btn-primary' data-bs-toggle='modal' data-bs-target='#modalWithdraw'>
								{i18n.t('pay_selected')}
							</button>
						) : (
							<></>
						)}
						<div className='card card-body border-0 shadow table-wrapper table-responsive'>
							{/* <pre>{JSON.stringify(editWithdraw)}</pre> */}
							<table className='table table-hover'>
								<thead>
									<tr>
										<th className='border-gray-200'>{i18n.t('choose')}</th>
										<th className='border-gray-200'>{i18n.t('user')}</th>
										<th className='border-gray-200'>{i18n.t('amount')}</th>
										<th className='border-gray-200'>{i18n.t('wallet')}</th>
										<th className='border-gray-200'>{i18n.t('date')}</th>
										<th className='border-gray-200'>{i18n.t('status')}</th>
									</tr>
								</thead>
								<tbody>
									{withdrawals?.map((w, i) => (
										<WithdrawRow w={w} key={i} chooseWithdraw={chooseWithdraw} editWithdraw={editWithdraw} />
									))}
								</tbody>
							</table>
						</div>
					</div>
				</>
				<Footer />
			</main>
			<Toast type={notification.type} text={notification.text} />
			<WithdrawModal withdraw={editWithdraw} totalSelected={totalSelected} payWithdraw={doPayWithdraw} />
			{/* <Toast type={notification.type} text={notification.text} /> */}
		</React.Fragment>
	);
}

export default Withdrawals;
