import axios from './BaseService';

const CALLS_URL = `${process.env.REACT_APP_API_URL}/calls/`;

export async function getCalls(token, page, limit, search) {
	const endPoint = `${CALLS_URL}list${page ? `?page=${page}` : ''}`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { limit, search }, { headers });
	return response.data;
}

export const getCallMessages = async (callId, token) => {
	const endPoint = `${CALLS_URL}call/${callId}`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.get(endPoint, { headers });
	return response.data;
};

export const sendCallMessages = async (data, token) => {
	const endPoint = `${CALLS_URL}reply`;
	const headers = {
		'Content-Type': 'application/json',
		Authorization: `${token}`,
	};
	const response = await axios.post(endPoint, data, { headers });
	return response.data;
};
