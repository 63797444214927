import { FormateDate } from '../../utils/CustomFormatations';
import { i18n } from '../../utils/translates/i18n';

export default function CallRow({ c, ReadCall }) {
	return (
		<li
			className={` my-1 border list-group-item list-group-item-action list-group-item-${c.statuses?.bg}`}
			onClick={() => ReadCall(c.id)}
			style={{ cursor: 'pointer' }}>
			<div className='row'>
				<div className='col-md-2 text-center'>
					<span className={`badge bg-${c.statuses?.bg} w-100`}>{i18n.t('date')}</span>
					<p className={`text-dark fw-bold mb-0`}>{FormateDate(c.createdAt, false, true)}</p>
				</div>
				<div className='col-md-2 text-center'>
					<span className={`badge bg-${c.statuses?.bg} w-100`}>{i18n.t('nick')}</span>
					<p className='fw-bold' title={c.user?.name}>
						{c.user?.nick?.toUpperCase()}
					</p>
				</div>
				<div className='col-md-6'>
					<div className='row'>
						<div className='col-md-5 text-center'>
							<span className={`badge bg-${c.statuses?.bg} w-100`}>{i18n.t('subject')}</span>
							<p className={`text-dark fw-bold mb-0`}>{c.subject}</p>
						</div>
						<div className='col-md-7'>
							<span className={`badge bg-${c.statuses?.bg} w-100`}>{i18n.t('description')}</span>
							<p className={`text-dark fw-bold mb-0`}>{c.description}</p>
						</div>
					</div>
				</div>
				<div className='col-md-2 text-center'>
					<span className={`badge bg-${c.statuses?.bg} w-100`}>{i18n.t('status')}</span>
					<p className={`text-dark fw-bold mb-0`}>{i18n.t(c.statuses.status)}</p>
				</div>
			</div>
		</li>
	);
}
