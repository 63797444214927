import React from 'react';

const ItemTranslate = props => {
	return (
		<li>
			<a href='#!' onClick={() => props.onClick(props.langs.ab)} title={props.langs.name} className='dropdown-item'>
				<img
					src={`/flags/1x1/${props.langs.flag}.svg`}
					alt={props.langs.ab}
					style={{ width: '20px', height: 'auto', marginRight: '5px' }}
					className='rounded-circle'
				/>{' '}
				{props.langs.name}
			</a>
		</li>
	);
};

export default ItemTranslate;
