import React from 'react';
import { FormateDate, FormateFiatCurrency } from '../../utils/CustomFormatations';
import { i18n } from '../../utils/translates/i18n';
import SwitchInput from '../../components/SwitchInput/SwitchInput';

/**
 * props:
 * - data
 * - onEditClick
 */
export default function WithdrawRow({ w, chooseWithdraw, editWithdraw }) {
	return (
		<>
			<tr>
				<td>
					{w.status === 1 || w.status === 2 ? (
						<SwitchInput
							id='choose'
							text={`# ${w.id}`}
							onChange={() => chooseWithdraw(w.id, w.amount)}
							isChecked={editWithdraw.includes(w.id)}
						/>
					) : (
						<span>#{w.id}</span>
					)}
				</td>
				<td>
					<a className={`m-0 p-0 text-left`} href='#!'>
						<span className='fw-bold'>{w.user?.nick?.toUpperCase()}</span>
						<br />
						<small>{w.user?.name}</small>
					</a>
				</td>
				<td>{FormateFiatCurrency(w.amount)}</td>
				<td>{w.wallet}</td>
				<td>{FormateDate(w.createdAt, true)}</td>
				<td>
					<span className={`btn btn-sm btn-${w.statuses?.bg} py-0 px-1`}>{i18n.t(w.statuses.status)}</span>
				</td>
			</tr>
		</>
	);
}
