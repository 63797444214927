/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
// import NewUserButton from './NewUserButton';
import UserRow from './UserRow';
import Pagination from '../../utils/pagination/Pagination';
import { getUsers /* deleteUser, startUser, stopUser */ } from '../../services/UsersService';
import Toast from '../../components/Toast/Toast';
// import SearchUser from './SearchUser';
import UserModal from './UserModal/UserModal';
import { i18n } from '../../utils/translates/i18n';

function Users() {
	const defaultLocation = useLocation();
	const history = useHistory();

	const [users, setUsers] = useState([]);
	const [editUser, setEditUser] = useState({});

	// PARA PAGINAÇÃO
	const token = sessionStorage.getItem('token');
	const [search, setSearch] = useState('');
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [lim, setLims] = useState(10);

	const doGetUsers = async () => {
		const success = await getUsers(token, page, lim, search);
		if (success.status) {
			console.log(success);
			setCount(success.list.count);
			setUsers(success.list.rows);
		} else {
			setNotification({ type: 'error', text: success.error });
		}
	};

	useEffect(() => {
		doGetUsers();
	}, [token, page, lim, search]);

	function getPage(location) {
		if (!location) location = defaultLocation;
		return new URLSearchParams(location.search).get('page');
	}

	useEffect(() => {
		return history.listen(location => {
			setPage(getPage(location));
		});
	}, [history]);
	// FIM PARA PAGINAÇÃO

	const [notification, setNotification] = useState({ type: '', text: '' });

	function onEditClick(event) {
		const id = event.target.id.replace('edit', '');
		setEditUser(users.find(u => u.id == id));
	}

	/* function onStopClick(event) {
		const id = event.target.id.replace('stop', '');
		stopUser(id)
			.then(result => {
				history.go(0);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onStartClick(event) {
		const id = event.target.id.replace('start', '');
		startUser(id)
			.then(result => {
				history.go(0);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}

	function onDeleteClick(event) {
		const id = event.target.id.replace('delete', '');
		deleteUser(id)
			.then(result => {
				history.go(0);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
			});
	}
	
	function onSearchChange(event) {
		setSearch(event.target.value);
	}
	*/

	function onModalSubmit(event) {
		history.go(0);
	}

	return (
		<React.Fragment>
			<Menu />
			<main className='content'>
				<div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
					<div className='d-block mb-4 mb-md-0'>
						<h2 className='h4'>{i18n.t('users')}</h2>
					</div>
				</div>
				<Pagination count={count} pageSize={lim}>
					<div className='align-items-center'>
						<div className='alert bg-dark m-0 py-2 d-flex justify-content-around align-items-center'>
							<i className='fas fa-search'></i>
						</div>
					</div>
					<div className='input-group mx-3'>
						<input
							className='form-control'
							value={search}
							onChange={e => setSearch(e.target.value)}
							placeholder={`${i18n.t('search')} ${i18n.t('by')} ${i18n.t('nick')}`}
						/>
					</div>
					<select className='form-select w-50' value={lim} onChange={e => setLims(e.target.value)}>
						<option className='bg-light' value='5'>
							5 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='10'>
							10 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='25'>
							25 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='50'>
							50 {i18n.t('registers')}
						</option>
						<option className='bg-light' value='100'>
							100 {i18n.t('registers')}
						</option>
					</select>
				</Pagination>
				<div className='card card-body border-0 shadow table-wrapper table-responsive'>
					<table className='table table-hover'>
						<thead>
							<tr>
								<th className='border-gray-200'>{i18n.t('name')}</th>
								<th className='border-gray-200'>{i18n.t('email')}</th>
								<th className='border-gray-200'>{i18n.t('traderbot')}</th>
								<th className='border-gray-200'>{i18n.t('action')}</th>
							</tr>
						</thead>
						<tbody>
							{users && users.length ? (
								users.map(user => (
									<UserRow
										key={user.id}
										data={user}
										onEditClick={onEditClick}
										// onStartClick={onStartClick}
										// onStopClick={onStopClick}
										// onDeleteClick={onDeleteClick}
									/>
								))
							) : (
								<React.Fragment></React.Fragment>
							)}
						</tbody>
					</table>
					<Pagination count={count} />
				</div>
				<Footer />
			</main>
			<UserModal data={editUser} onSubmit={onModalSubmit} />
			<Toast type={notification.type} text={notification.text} />
		</React.Fragment>
	);
}

export default Users;
