const messages = {
	es: {
		translations: {
			a_crase: 'El',
			about: 'Sobre nosotros',
			above_the_same: 'Por encima exactamente lo mismo',
			action: 'Acción',
			actionTemplates: 'Modelos en stock',
			activate_date: 'fecha de activacion',
			ad: 'Anuncio',
			add: 'Adhesión',
			add_option: 'añadir opción',
			add_payment: 'Agregar pago',
			add_question: 'Agregar pregunta',
			add_receipt: 'Agregar recibo',
			add_wallet: 'Agregar billetera',
			address: 'DIRECCIÓN',
			admin: 'Administración',
			advance: 'Avance',
			affected: 'alcanzó',
			after: 'Después',
			air_transport: 'Transporte aereo',
			ajust_limite: 'Ajuste de límite',
			ajust_saldo: 'Ajuste de saldo',
			all: 'Todo',
			all_departments: 'Todos los departamentos',
			allies_of: 'aliados de',
			allRightsReserved: 'Todos los derechos reservados.',
			almost_there: 'Casi allí',
			already_offset: 'ya compensado',
			already_registered: '¿Ya registrado? Haga clic aquí e ingrese',
			amount: 'Valor',
			and: 'Es',
			and_more: 'y más',
			answer: 'Respuesta',
			ao: 'hacia',
			approve: 'Aprobar',
			April: 'Abril',
			asc: 'Ascendente',
			at: 'en',
			August: 'Agosto',
			automations: 'Automatizaciones',
			awaiting_email_confirmation: 'En espera de confirmación por correo electrónico!',
			b_order: 'Orden',
			b_orders: 'pedidos',
			back: 'para volver',
			bairroInteresse: 'Vecindario',
			balance: 'Balance',
			balance_available: 'Saldo disponible',
			balance_network: 'Equilibrio de red',
			banned: 'Prohibido',
			before_contact: 'Antes de contactarnos',
			binary: 'Binario',
			birth: 'Nacimiento',
			blind_man: 'invertido',
			body_monthly:
				'Su tarifa mensual ha vencido y su BOT TRADER ha sido desactivado, pague el giro postal y disfrute de sus beneficios nuevamente',
			bonus_saldo_trader: 'Bonificación de saldo de comerciante',
			but_possible_register: 'Aun así, es posible registrarse, pero no se relacionará con ningún amigo referente.',
			buyer: 'Comprador',
			by: 'por',
			bye: 'Hasta luego',
			call: 'Llamado',
			call_to: 'Llamar a',
			calls: 'llamado',
			cancel: 'Cancelar',
			canceled: 'Cancelado',
			career_plan: 'Plano de carrera',
			casa_verde_amarela: 'casa verde amarilla',
			cash_payment: 'Pago en dinero',
			cash_payment_return: 'devolución de pago en efectivo',
			cash_receipt: 'recibo de caja',
			change_indicant: 'Indicador de cambio',
			change_photo: 'Cambiar foto',
			change_plan: 'cambio de plan',
			check_payment: 'pago en cheque',
			check_payment_return: 'Pago de devolución con cheque',
			check_receipt: 'recibo por cheque',
			choose: 'Elegir',
			cidadeInteresse: 'Ciudad',
			city: 'Ciudad',
			claims: 'Reclamos',
			clean: 'Limpiar',
			click_and_know: 'Haz clic y conoce',
			click_to_copy_the_wallet: 'Haga clic para copiar la cartera',
			client: 'Cliente',
			clients: 'Clientes',
			cnh: 'Licencia de conducir (licencia de conducir)',
			cnhDate: 'Fecha de emisión de la licencia de conducir',
			cnpj: 'CNPJ',
			cnpj_invalid: 'CNPJ no válido',
			color: 'Color',
			commercial: 'Comercial',
			company: 'Nombre de la empresa',
			complement: 'Complementar',
			comporRenda: 'Ir a componer ingresos con alguien',
			compRenda: 'Tipo de comprobante de ingresos',
			confirm_approve: 'confirmar aprobación',
			confirm_delete: 'confirmar la eliminación',
			confirm_new_password: 'Confirmar nueva contraseña',
			confirm_new_password_security: 'Confirmar nueva contraseña de seguridad',
			confirm_password: 'Confirmar contraseña de acceso',
			confirm_password_security: 'Confirmar contraseña de seguridad',
			confirmed: 'Confirmado',
			confirmed_email: 'correo electrónico confirmado',
			congratulations: 'Felicidades',
			contact: 'Contacto',
			contract: 'Contrato',
			copied: 'copiado',
			copy: 'Copiar',
			copy_success: '¡Copiado con éxito!',
			correspondences: 'Correspondencia',
			countries: 'países',
			country: 'País',
			cpf: 'CPF',
			cpf_invalid: 'CPF no válido',
			create_order_success: '¡Pedido creado con éxito!',
			created: 'Registrado en',
			cred: 'Crédito',
			cred_binario: 'Crédito Binario',
			cred_deb: 'Créditos/Débitos',
			cred_game_donate: 'Juego Donar crédito',
			cred_ind_direta: 'Crédito de referencia indirecta',
			cred_ind_indireta: 'Crédito de referencia directa',
			cred_mandala: 'Crédito Mandala',
			cred_pagar_com_saldo: 'Crédito Pagar con Saldo',
			cred_pontos: 'Puntos de crédito',
			cred_recb_trader: 'Comerciante de recepción de crédito',
			cred_renovacao: 'Crédito de renovación',
			cred_saldo_disp: 'Saldo disponible de crédito',
			cred_upgrade: 'Actualizar crédito',
			credit_card_payment: 'Pago con tarjeta de crédito',
			credit_card_payment_return: 'Devolución de pago con tarjeta de crédito',
			credit_card_receipt: 'Recibo de tarjeta de crédito',
			current: 'Actual',
			current_password: 'Contraseña actual',
			current_wallet: 'Cartera actual',
			dashboard: 'Panel de control',
			data_added: 'Datos agregados',
			data_saved: 'Datos guardados',
			datas: 'Datos',
			date: 'Fecha',
			date_first_installment: 'primera fecha de pago',
			deb_de_trader: 'Deuda del comerciante',
			deb_game_donate: 'Débito de donación de juegos',
			deb_saldo: 'Balance de débito',
			deb_saldo_disp: 'Débito Saldo Disponible',
			deb_val_indev: 'Importe inadecuado de débito',
			debit_card_payment: 'pago con tarjeta de debito',
			debit_card_payment_return: 'Devolución de pago con tarjeta de débito',
			debit_card_receipt: 'recibo de tarjeta de débito',
			debt: 'Débito',
			debt_td: 'deuda',
			December: 'Diciembre',
			decImpRenda: 'declarar impuesto sobre la renta',
			deleted_document: 'El documento ha sido eliminado de su registro',
			department: 'Departamento',
			dependents: 'dependientes',
			desc: 'Hacia abajo',
			description: 'Descripción',
			details: 'Detalles',
			dev_econom: 'Desarrollo economico',
			developer: 'Desarrollador',
			directly_indicated: 'directamente indicado',
			directly_indicateds: 'Designado directamente',
			directs: 'directo',
			district: 'barrio / distrito',
			divorced: 'Divorciado',
			doa_men_receb: 'Donación de plataforma mensual recibida',
			doacao_td: 'Donación',
			doctorate_complete: 'Doctorado - Completo',
			doctorate_incomplete: 'Doctorado - Incompleto',
			document: 'Documento',
			document_already_registered: 'Documento ya registrado',
			documents: 'Documentos',
			donate: 'Donar',
			donor: 'Donante',
			dont_know_zip: '¿No sabes el código postal? Haga clic aquí',
			dou_fe: 'Confirmo que la información anterior es correcta.',
			downloads: 'Descargar archivos',
			drop_images: 'Haga clic o arrastre las imágenes aquí',
			due_date: 'Madurez',
			edit: 'Para editar',
			edit_option: 'opción de edición',
			edit_question: 'editar pregunta',
			email: 'Correo electrónico',
			email_already_registered: 'correo electrónico ya registrado',
			email_and_or_password_wrong: '¡Correo electrónico y/o contraseña incorrectos!',
			email_for_login: 'Este correo electrónico será su inicio de sesión',
			email_or_password_wrong: 'Correo o contraseña incorrectos',
			employee: 'Empleado',
			employees: 'Empleados',
			empty: 'Vacío',
			entrada: 'Tiene valor de entrada? En caso afirmativo, ¿qué valor?',
			entries_until_today: 'Entradas hasta la fecha',
			error_confirm_password: 'La confirmación y la nueva contraseña deben ser iguales',
			error_email: 'Formato de correo inválido',
			error_password: 'Contraseña incorrecta',
			error_phone: 'Numero de telefono invalido',
			estadoInteresse: 'estado',
			exchange: 'Reemplazo',
			expired: 'Venció',
			extorno_td: 'devolver',
			extra: 'Extra',
			extract: 'Extracto',
			extracts: 'extractos',
			fantasy: 'Fantasía',
			fatherName: 'Nombre del Padre',
			February: 'Febrero',
			fee: 'Cargo por retiro',
			feedback: 'Devolver',
			feminine: 'Femenino',
			fgts: 'Valor de su FGTS',
			field_required: 'Campo obligatorio',
			final: 'Final',
			finance: 'Financiero',
			financial: 'Financiero',
			financial_payment: 'pago financiero',
			financial_payment_return: 'Devolución de pago financiero',
			financial_receipt: 'recibo financiero',
			financing: 'Financiación',
			financings: 'Financiación',
			first_10_pages: 'primeras 10 paginas',
			fisica: 'Persona física',
			for_year: 'Por año',
			forget_password: 'Olvidé la contraseña',
			forgot_password: 'Olvido la contraseña',
			form: 'Molde',
			form_of_payment: 'Forma de pago',
			found: 'Encontró',
			founds: 'Encontró',
			friend_indicated: 'Recomendado por mis amigos',
			friend_indicateds: 'Recomendado por mis amigos',
			fuel: 'Combustible',
			full_name: 'Nombre completo',
			funcPublico: '¿Eres un servidor público?',
			fundamental_complete: 'Elemental - Completo',
			fundamental_incomplete: 'Elemental - Incompleto',
			ganh_diario: 'Ganancias diarias',
			gender: 'Género',
			grand_total: 'Total general',
			group: 'Grupo',
			has_withdraw_today: 'Ya se ha solicitado un retiro hoy',
			home: 'Comenzar',
			how_it_works: 'Como funciona',
			how_to_call: '¿Cómo quieres que te llamen?',
			id_td: 'Indicación Directa',
			images: 'Imágenes',
			in_construction: 'En construcción',
			in_order: 'En orden',
			in_stock: 'En stock',
			indicant: 'Indicador',
			indicated_by: 'Estás siendo nominado por',
			indicated_not_found: 'No se encontró el Indicador indicado...',
			indicateds: 'Indicado',
			indirects: 'Indirecto',
			info: 'Boletines',
			info_contact: 'Información del contacto',
			initial: 'Inicial',
			insert_new: 'Insertar nuevo',
			installments: 'cuotas',
			intelligence: 'Inteligencia',
			interesse: '¿Cuál es la región de interés?',
			invalid_contact: 'Formato de contacto inválido',
			invalid_images: 'imágenes inválidas',
			invalid_token: 'simbolo no valido',
			invite: 'Invitar',
			invite_friends: 'Invita a tus amigos',
			January: 'Enero',
			July: 'Julio',
			June: 'Junio',
			juridica: 'Persona jurídica',
			know_exact_result: '¿Sabes el valor exacto?',
			lead: 'Juntos construiremos un nuevo futuro.',
			learn_more: 'Sepa mas',
			left: 'Izquierda',
			left_preferential: 'Preferir el lado izquierdo',
			legalNature: 'Naturaleza jurídica',
			level: 'Nivel',
			limite_excedido: 'Límite de débito excedido',
			linear: 'Lineal',
			link_email: '¡Haga clic en el enlace enviado al correo electrónico registrado!',
			link_invite_copy: 'Compartir enlace copiado con éxito',
			liquid: 'Líquido',
			list: 'Lista',
			loading: 'Cargando',
			location: 'Ubicación',
			login: 'Entrar',
			logout: 'Salir',
			male: 'Masculino',
			manage_users: 'Administrar usuarios',
			manager: 'Gerente',
			mandala: 'mandalas',
			mandalas: 'Mándalas',
			manufacturer: 'Fabricante',
			March: 'Marzo',
			maritalStatus: 'Estado civil',
			market: 'Mercado',
			married: 'Casado',
			master_complete: 'Máster - Completo',
			master_incomplete: 'Maestría - Incompleta',
			May: 'Puede',
			message: 'Mensaje',
			messageNotSent: 'No fue posible enviar su mensaje',
			messageSent: 'Su mensaje ha sido enviado con éxito',
			middle_complete: 'Medio - Completo',
			middle_incomplete: 'Medio - Incompleto',
			min_amount_error: 'El valor debe ser al menos:',
			min_withdraw: 'Monto Mínimo para Retiro',
			missing: 'Desaparecido',
			mission: 'Misión',
			mission_vision_values: 'Misión, visión y valores',
			model: 'Modelo',
			monitors: 'monitores',
			monthly: 'Mensualidad',
			motherName: 'Nombre de la madre',
			movements: 'Movimientos',
			msg_renovacao: 'Ha alcanzado su límite de ganancias, renueve su paquete.',
			my_account: 'Mi cuenta',
			my_wallet: 'Mi billetera',
			mystock: 'Mi acción',
			name: 'Nombre',
			nascMaisVelhoRenda2: '¿Cuál es la fecha de nacimiento del mayor?',
			nationality: 'Nacionalidad (País de nacimiento)',
			naturalness: 'Lugar de nacimiento (Ciudad donde nació)',
			nature: 'Naturaleza',
			network: 'Red',
			new: 'Nuevo',
			new_call: 'Nueva llamada',
			new_financing: 'Nuevo Financiamiento',
			new_order: 'Nueva orden',
			new_password: 'Nueva contraseña',
			new_password_security: 'Nueva contraseña de seguridad',
			new_seller: 'Nuevo vendedor',
			new_wallet: 'Nuevo Portafolio',
			news: 'Noticias',
			next: 'Próximo',
			nick: 'Usuario',
			nis: 'PIS/NIS',
			nis_already_registered: 'PIS/NIS ya registrado',
			no: 'No',
			no_approve: 'aun no lo apruebo',
			no_delete: 'no excluir',
			no_literate: 'no alfabetizado',
			no_repeat: 'Los datos no se pueden repetir.',
			no_results_found: 'Ningún resultado encontrado',
			no_services_or_products_added: 'No se agregaron servicios ni productos',
			no_settings: 'Ve a Configuración y completa tus datos.',
			none_friends_indicated: 'Todavía no he recomendado a ningún amigo',
			not_authorized: 'No autorizado',
			not_found: 'No encontrado',
			not_login: '¡No fue posible ingresar, verifique los datos y vuelva a intentarlo!',
			not_orders: 'Sin giros postales',
			not_permission: 'No tienes permiso para acceder a esta página.',
			not_registered: 'Todavía no estas registrado? Haga clic aquí y regístrese',
			not_results: 'No se encontraron registros',
			not_self_register: 'No se puede registrar',
			not_today_entry: 'Todavía no hemos tenido entradas hoy.',
			notfound_ad: 'No se encontraron anuncios',
			November: 'Noviembre',
			number: 'Número',
			obs: 'Comentarios',
			October: 'Octubre',
			of: 'en',
			off: 'aniquilado',
			on_total: 'del total',
			open: 'Abierto',
			opening: 'Apertura',
			operation: 'Actuación',
			ordenation: 'ordenación',
			order: 'Orden',
			order_not_found: 'pedido no encontrado',
			orders: 'Peticiones',
			other: 'Otro',
			other_services_or_products: 'Otros Servicios o Productos',
			paid: 'Pagado',
			paid_date: 'día de pago',
			partial: 'Parcialmente pagado',
			passport: 'Pasaporte',
			password: 'Contraseña',
			password_security: 'contraseña de seguridad',
			pay: 'Pagar',
			pay_generate: 'generar pago',
			pay_selected: 'Pago Seleccionado',
			pay_value_in: 'pagar la cantidad en',
			payable: 'Pagar',
			payer: 'Pagador',
			payment: 'Pago',
			ped_retirada: 'Solicitud de Retiro',
			ped_retirada_gan_diario: 'Solicitud de Retiro de Ganancias Diarias',
			pendent: 'Pendiente',
			people: 'Gente',
			person: 'Persona',
			person_found_in_other_prefecture: 'Persona ya empadronada en otro municipio',
			personal: 'Tipo',
			personal_addresses: 'direcciones',
			personal_contacts: 'Contactos',
			personal_data: 'Datos personales',
			personal_infos: 'Información',
			personal_pcva: 'Programa Casa Verde y Amarilla',
			phone: 'Teléfono',
			pix: 'PIX',
			pix_payment: 'Pago en PIX',
			pix_payment_return: 'Devolución de pago en PIX',
			pix_receipt: 'Recibo en PIX',
			pl_carreira: 'Plano de carrera',
			plan: 'Departamento',
			plates: 'Platos',
			please_wait: 'Espere por favor',
			plus: 'más',
			point: 'Punto',
			points: 'Puntos',
			portfolio: 'portafolio',
			pos_complete: 'Lato sensu - Completa',
			pos_incomplete: 'Lato sensu - Incompleto',
			prefecture: 'Ayuntamiento',
			prefecture_addresses: 'direcciones',
			prefecture_contacts: 'Contactos',
			prefecture_data: 'Datos del Ayuntamiento',
			prefecture_infos: 'Información',
			prefectures: 'ayuntamientos',
			prev: 'Anterior',
			price: 'Precio',
			print: 'Imprimir',
			privacy_policies: 'política de privacidad',
			private_works: 'Obras Privadas',
			profession: 'Profesión',
			profile: 'Perfil',
			program: 'Programa',
			programs: 'Software',
			project: 'Proyecto',
			projects: 'Proyectos',
			public_works: 'Obras Públicas',
			purchase: 'Compra',
			qualify_binary: 'Calificación binaria',
			qualquer: 'Cualquier',
			quantity: 'Cantidad',
			quotation: 'Precio',
			read: 'Leer',
			receivable: 'Para recibir',
			recipient: 'receptor',
			recommendations: 'Recomendaciones',
			redefine_password: 'Redefinir contraseña',
			register: 'Registro',
			register_people: 'Registro de Personas',
			register_wallet: 'Registre una billetera BTC para hacer retiros',
			registers: 'Registros',
			registers_users: 'registro de usuario',
			reject: 'Rechazar',
			remaining: 'Restante',
			remove: 'Para eliminar',
			rendaIndividual: 'Ingreso bruto individual',
			renew: 'Renovar',
			renew_mandala: 'Renovación de mandalas',
			reply: 'Responder',
			reports: 'Informes',
			required_data_not_sent: 'Datos requeridos no enviados',
			resend_email: 'reenvíe el correo electrónico',
			residential: 'Residencial',
			responded: 'Contestada',
			restricao: '¿Tiene restricción a su nombre? (SPC/Serasa)',
			result: 'Resultado',
			results: 'Resultados',
			resume_data: 'Resumen de datos',
			revoked: 'revocado',
			rg: 'Registro General (Identidad)',
			rgAgency: 'emisor de RG',
			rgDate: 'Fecha de emisión de RG',
			rgUf: 'estado de identificación',
			right: 'Bien',
			right_preferential: 'Preferir el lado derecho',
			roi: 'Ganancias diarias',
			sale: 'Venta',
			saq_duplicado: 'Retiro duplicado',
			save: 'Ahorrar',
			schooling: 'Educación',
			search: 'Buscar',
			secretary: 'Secretario',
			security_password: 'contraseña de seguridad',
			see: 'Para ver',
			see_advents: 'ver los anuncios',
			see_call: 'ver llamada',
			see_details: 'Ver detalles',
			see_details_wallet: 'Ver detalles de la cartera',
			see_financing: 'Ver financiación',
			see_order: 'Ver pedido',
			see_user: 'Ver usuarios',
			select_vehicle: 'seleccione el vehículo',
			self_register: 'Registro',
			seller: 'Vendedor',
			send_call: 'Enviar ticket',
			send_to: 'Enviar para',
			September: 'Septiembre',
			settings: 'ajustes',
			share_your_link: 'Comparte tu enlace',
			show: 'presentarse a',
			show_from: 'mostrando desde',
			side: 'Lado',
			sign_in_to_start_your_session: 'Inicia sesión para iniciar tu sesión',
			sign_up: 'Registro',
			single: 'Soltero',
			sold: 'Vendido',
			state: 'estado',
			status: 'Estado',
			strategies: 'Estrategias',
			street: 'Camino',
			subject: 'Sujeto',
			submit: 'Mandar',
			submit_placeholder: 'Por favor complete su correo electrónico',
			subscribe: 'Suscríbete para recibir nuestras novedades',
			subtitle: 'Subtítulo',
			success_update_user: '¡Usuario actualizado con éxito!',
			success_wallet: 'Portafolio actualizado con éxito!',
			suggestions: 'sugerencias',
			superior_complete: 'Superior completo',
			superior_incomplete: 'Superior incompleta',
			supervisor: 'Supervisor',
			support: 'Soporte',
			support_materials: 'Materiales de apoyo',
			suspended: 'Suspendido',
			symbols: 'simbolos',
			system: 'Sistema',
			target: 'Meta',
			tempoCarteira: '¿Cuánto tiempo ha estado el registro en la cartera?',
			terms: 'Acepto los términos de uso',
			this_value_in: 'Este valor en',
			title: 'Título',
			to: 'Hasta',
			to_pay: 'Apagar',
			to_receive: 'A recibir',
			today_entry: 'Entrada de hoy',
			token_not_found_or_already_used: 'Token no encontrado o ya utilizado',
			total: 'Total',
			total_entry: 'Entrada total',
			traderbot: 'comerciante de bots',
			transf_credito: 'Transferencia de credito',
			transf_debito: 'Transferencia de débito',
			transfer_payment: 'transferencia de pago',
			transfer_payment_return: 'Devolución pago transferencia',
			transfer_receipt: 'recibo en transferencia',
			transparency: 'Transparencia',
			tx_adm_cred: 'Administrar impuestos',
			tx_adm_deb: 'Administrar impuestos',
			tx_retirada: 'Cargo por retiro',
			tx_retirada_gan_diario: 'Tasa de retiro de ganancias diarias',
			type: 'Tipo',
			update: 'Actualizar',
			update_wallet: 'Actualizar Portafolio',
			upgrade: 'Mejora',
			used: 'Usado',
			user: 'Usuario',
			user_active: 'usuario activo',
			user_already_exists: '¡El usuario ya existe!',
			user_and_or_password_wrong: '¡Usuario y/o contraseña incorrectos!',
			user_inactive: 'usuario inactivo',
			user_not_found: 'Usuario no encontrado',
			user_not_logged: 'Usuario no registrado',
			user_or_password_wrong: 'usuario o contraseña incorrectos',
			users: 'Usuarios',
			users_list: 'Lista de usuarios',
			valid_images: 'imágenes válidas',
			valorRenda2: '¿Cuál es el valor del segundo ingreso?',
			value: 'Valor',
			value_donate_on: 'Valor de la donación en',
			value_financed: 'cantidad financiada',
			value_installment: 'valor de la cuota',
			values: 'Valores',
			vehicle: 'Vehículo',
			vehicles: 'vehículos',
			view_order: 'Ver Pedido',
			vision: 'Visión',
			visitor: 'Visitante',
			voucher: 'Vale',
			wait_confirm: 'Esperando confirmacion',
			waiting: 'Espera',
			waiting_email: 'El registro se realizó con éxito, ahora solo queda confirmar el correo electrónico.',
			waiting_payment: 'En espera de pago',
			wallet: 'portafolio',
			wallet_generate: 'Generar Portafolio',
			wallets: 'carteras',
			want_donate: 'quiero donar',
			want_now_more_about: 'quiero saber mas sobre',
			warning: 'ATENCIÓN',
			we_are: 'Son',
			webhooks: 'webhooks',
			welcome: 'Sea bienvenido',
			who_we_are: 'Quienes somos',
			who_we_are_subtitle: 'Una breve descripción sobre el',
			widower: 'Viudo',
			will_expire_in: 'caducará en',
			with: 'con',
			withdraw: 'Retiro',
			withdraw_sended_email: '¡Retiro solicitado, revisa tu correo electrónico y haz clic en el enlace para autorizar!',
			withdrawal: 'Retiros',
			withdrawals: 'Retiros',
			year: 'Año',
			years: 'Años',
			yes: 'Sí',
			your_country: 'Su país',
			your_dashboard: 'Este es su Panel de Control.',
			your_link: 'Su enlace',
			zipcode: 'Código postal',
			amount_above_balance: 'Saldo insuficiente',
			symbol: 'Símbolo',
			close: 'Cierre',
			high: 'Alto',
			low: 'Bajo',
			order_book: 'Libro de pedidos',
			coin: 'Moneda',
			free: 'Libro',
			locked: 'Obstruido',
			you_have: 'Tienes',
			buy_volume: 'volumen de compra',
			sell_volume: 'Volumen de venta',
			estimate: 'Estimado',
			photo: 'Foto',
			telegram_chat_id: 'ID de chat de Telegram',
			pendent_order: 'Tienes pedidos pendientes',
			active_automations: 'Automacaciones activas',
			active_monitors: 'Monitores activos',
			active_users: 'Usuarios activos',
			active_connections: 'Conexiones activas',
			ticker_health: 'Salud del ticker',
			book_health: 'Salud del libro',
			enter_your_new_password: 'Introduzca su nueva contraseña',
			confirm_your_new_password: 'Ingrese su nueva contraseña nuevamente',
			alerts: 'Advertencia',
			alert: 'alerta',
			telegram: 'Telegram',
			logs: 'Logs',
			release_withdrawals: 'liberación de Retiros',
			bot_active: 'Permitir bot',
			available: 'Disponible',
		},
	},
};

export { messages };
