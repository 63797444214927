/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import { saveUser } from '../../../services/UsersService';
import SelectLimit from './SelectLimit';
import SwitchInput from '../../../components/SwitchInput/SwitchInput';
import { i18n } from '../../../utils/translates/i18n';

/**
 * props:
 * - data
 * - onSubmit
 */
function UserModal(props) {
	const DEFAULT_USER = {
		name: '',
		email: '',
		password: '',
		limitId: 0,
		telegramChat: '',
		phone: '',
		isActive: false,
		photo: '',
		passwordSecurity: '',
		status: '',
		binaryQualify: false,
		activity: false,
		releaseWithdrawals: false,
	};

	const [error, setError] = useState('');

	const [user, setUser] = useState(DEFAULT_USER);

	const btnClose = useRef('');
	const btnSave = useRef('');

	function onSubmit(event) {
		saveUser(user.id, user)
			.then(result => {
				btnClose.current.click();
				if (props.onSubmit) props.onSubmit(result);
			})
			.catch(err => {
				console.error(err.response ? err.response.data : err.message);
				setError(err.response ? err.response.data : err.message);
			});
	}

	function onInputChange(event) {
		setUser(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
	}

	useEffect(() => {
		setUser(props.data);
	}, [props.data.id]);

	useEffect(() => {
		const modal = document.getElementById('modalUser');
		modal.addEventListener('hidden.bs.modal', event => {
			setUser({ ...DEFAULT_USER });
		});
	}, []);

	return (
		<div
			className='modal fade'
			id='modalUser'
			tabIndex='-1'
			role='dialog'
			aria-labelledby='modalTitleNotify'
			aria-hidden='true'>
			<div className='modal-dialog modal-lg modal-dialog-centered' role='document'>
				<div className='modal-content'>
					<div className='modal-header'>
						<p className='modal-title' id='modalTitleNotify'>
							{i18n.t('edit')} {i18n.t('user')}
						</p>
						<button
							ref={btnClose}
							type='button'
							className='btn-close'
							data-bs-dismiss='modal'
							aria-label='close'></button>
					</div>
					<div className='modal-body'>
						<div className='form-group'>
							<div className='row'>
								<div className='col-md-3 mb-3'>
									<div className='form-group'>
										<img
											src={`${process.env.REACT_APP_API_URL}/images/${user.photo || 'user.png'}`}
											crossOrigin='anonymous'
											alt='user'
											className={`img-thumbnail img-fluid shadow`}
										/>
									</div>
								</div>
								<div className='col-md-9'>
									<div className='row'>
										<div className='col-12 mb-3'>
											<div className='form-group'>
												<label htmlFor='name'>{i18n.t('name')}:</label>
												<input
													type='text'
													id='name'
													className='form-control'
													onChange={onInputChange}
													value={user.name || ''}
													placeholder="User's full name"
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-12 mb-3'>
											<div className='form-group'>
												<label htmlFor='email'>{i18n.t('email')}:</label>
												<input
													type='text'
													id='email'
													className='form-control'
													onChange={onInputChange}
													value={user.email || ''}
													placeholder='user@email.com'
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-4 mb-3'>
									<div className='form-group'>
										<label htmlFor='phone'>{i18n.t('phone')}:</label>
										<input
											type='text'
											id='phone'
											className='form-control'
											onChange={onInputChange}
											value={user.phone || ''}
											placeholder='+5551123456789'
										/>
									</div>
								</div>
								<div className='col-md-4 mb-3'>
									<div className='form-group'>
										<label htmlFor='telegramChat'>
											Telegram Chat ID:
											<a className='badge bg-secondary py-1' href='/telegram-chat' target='_self'>
												?
											</a>
										</label>
										<input
											type='text'
											id='telegramChat'
											className='form-control'
											onChange={onInputChange}
											value={user.telegramChat || ''}
											placeholder='123456789'
										/>
									</div>
								</div>
								<div className='col-md-4 mb-3'>
									<div className='form-group'>
										<label htmlFor='limit'>{i18n.t('traderbot')}:</label>
										<SelectLimit id={user.limitId} onChange={onInputChange} />
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-3 mb-3'>
									<div className='form-group'>
										<SwitchInput
											id='isActive'
											text={`${i18n.t('bot_active')}?`}
											onChange={onInputChange}
											isChecked={user.isActive || false}
										/>
									</div>
								</div>
								{/* <div className='col-md-3 mb-3'>
									<div className='form-group'>
										<SwitchInput
											id='binaryQualify'
											text={`${i18n.t('qualify_binary')}?`}
											onChange={onInputChange}
											isChecked={user.binaryQualify || false}
										/>
									</div>
								</div> */}
								{/* <div className='col-md-3 mb-3'>
									<div className='form-group'>
										<SwitchInput
											id='activity'
											text={`${i18n.t('user_active')}?`}
											onChange={onInputChange}
											isChecked={user.activity || false}
										/>
									</div>
								</div> */}
								<div className='col-md-3 mb-3'>
									<div className='form-group'>
										<SwitchInput
											id='releaseWithdrawals'
											text={`${i18n.t('release_withdrawals')}?`}
											onChange={onInputChange}
											isChecked={user.releaseWithdrawals || false}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='modal-footer'>
						{error && <div className='alert alert-danger mt-1 col-9 py-1'>{error}</div>}

						<button ref={btnSave} type='button' className='btn btn-sm btn-primary' onClick={onSubmit}>
							{i18n.t('save')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserModal;
