const messages = {
	la: {
		translations: {
			a_crase: 'ال',
			about: 'معلومات عنا',
			above_the_same: 'فوق نفس الشيء بالضبط',
			action: 'فعل',
			actionTemplates: 'نماذج الأسهم',
			activate_date: 'تفعيل التسجيل',
			ad: 'إعلان',
			add: 'انضمام',
			add_option: 'إضافة خيار',
			add_payment: 'إضافة الدفع',
			add_question: 'أضف سؤال',
			add_receipt: 'أضف الإيصال',
			add_wallet: 'أضف المحفظة',
			address: 'عنوان',
			admin: 'إدارة',
			advance: 'يتقدم',
			affected: 'وصل',
			after: 'بعد',
			air_transport: 'النقل الجوي',
			ajust_limite: 'تعديل الحد',
			ajust_saldo: 'تعديل التوازن',
			all: 'الجميع',
			all_departments: 'جميع الإدارات',
			allies_of: 'حلفاء',
			allRightsReserved: 'كل الحقوق محفوظة.',
			almost_there: 'اوشكت على الوصول',
			already_offset: 'تعويض بالفعل',
			already_registered: 'مسجل بالفعل؟ انقر هنا وادخل',
			amount: 'قيمة',
			and: 'إنها',
			and_more: 'و اكثر',
			answer: 'إجابة',
			ao: 'الى',
			approve: 'ليوافق',
			April: 'أبريل',
			asc: 'تصاعدي',
			at: 'في',
			August: 'أغسطس',
			automations: 'الأتمتة',
			awaiting_email_confirmation: 'في انتظار تأكيد البريد الإلكتروني!',
			b_order: 'طلب',
			b_orders: 'طلبات',
			back: 'للعودة',
			bairroInteresse: 'حيّ',
			balance: 'توازن',
			balance_available: 'الرصيد المتاح',
			balance_network: 'توازن الشبكة',
			banned: 'محظور',
			before_contact: 'قبل الاتصال بنا',
			binary: 'الثنائية',
			birth: 'ولادة',
			blind_man: 'عكس',
			body_monthly:
				'انتهت صلاحية الرسوم الشهرية الخاصة بك وتم إلغاء تنشيط BOT TRADER الخاص بك ، يرجى دفع الحوالة المالية والاستمتاع بمزاياك مرة أخرى',
			bonus_saldo_trader: 'مكافأة رصيد المتداول',
			but_possible_register: 'ومع ذلك ، من الممكن التسجيل ، لكن لن يكون مرتبطًا بأي صديق مُحيل.',
			buyer: 'مشتر',
			by: 'لكل',
			bye: 'أراك لاحقًا',
			call: 'مُسَمًّى',
			call_to: 'دعوة ل',
			calls: 'مُسَمًّى',
			cancel: 'يلغي',
			canceled: 'ألغيت',
			career_plan: 'المسار المهني',
			casa_verde_amarela: 'البيت الأخضر الأصفر',
			cash_payment: 'دفع نقدا',
			cash_payment_return: 'عائد الدفع النقدي',
			cash_receipt: 'إيصال الدفع',
			change_indicant: 'مؤشر التغيير',
			change_photo: 'غير الصوره',
			change_plan: 'خطة التغيير',
			check_payment: 'الدفع بشيك',
			check_payment_return: 'إعادة الدفع بشيك',
			check_receipt: 'الاستلام بشيك',
			choose: 'لإختيار',
			cidadeInteresse: 'مدينة',
			city: 'مدينة',
			claims: 'المطالبات',
			clean: 'لينظف',
			click_and_know: 'انقر واعرف',
			click_to_copy_the_wallet: 'انقر لنسخ المحفظة',
			client: 'عميل',
			clients: 'عملاء',
			cnh: 'رخصة القيادة (رخصة القيادة)',
			cnhDate: 'تاريخ اصدار رخصة القيادة',
			cnpj: 'CNPJ',
			cnpj_invalid: 'CNPJ غير صالح',
			color: 'لون',
			commercial: 'تجاري',
			company: 'اسم الشركة',
			complement: 'إطراء',
			comporRenda: 'اذهب لتكوين دخل مع شخص ما',
			compRenda: 'نوع إثبات الدخل',
			confirm_approve: 'تأكيد الموافقة',
			confirm_delete: 'تأكيد الحذف',
			confirm_new_password: 'تأكيد كلمة المرور الجديدة',
			confirm_new_password_security: 'قم بتأكيد كلمة مرور الأمان الجديدة',
			confirm_password: 'تأكيد كلمة مرور الوصول',
			confirm_password_security: 'قم بتأكيد كلمة مرور الأمان',
			confirmed: 'مؤكد',
			confirmed_email: 'البريد الإلكتروني المؤكد',
			congratulations: 'تهانينا',
			contact: 'اتصال',
			contract: 'عقد',
			copied: 'نسخ',
			copy: 'ينسخ',
			copy_success: 'تم النسخ بنجاح!',
			correspondences: 'مراسلة',
			countries: 'بلدان',
			country: 'دولة',
			cpf: 'CPF',
			cpf_invalid: 'CPF غير صالح',
			create_order_success: 'تم إنشاء الطلب بنجاح!',
			created: 'مسجل في',
			cred: 'ائتمان',
			cred_binario: 'ائتمان ثنائي',
			cred_deb: 'الاعتمادات / الديون',
			cred_game_donate: 'لعبة تبرع الائتمان',
			cred_ind_direta: 'ائتمان الإحالة غير المباشر',
			cred_ind_indireta: 'ائتمان الإحالة المباشر',
			cred_mandala: 'ماندالا الائتمان',
			cred_pagar_com_saldo: 'دفع الائتمان مع الرصيد',
			cred_pontos: 'نقاط الائتمان',
			cred_recb_trader: 'تاجر استلام الائتمان',
			cred_renovacao: 'تجديد الائتمان',
			cred_saldo_disp: 'الرصيد المتوفر بالائتمان',
			cred_upgrade: 'ترقية الائتمان',
			credit_card_payment: 'الدفع ببطاقة الائتمان',
			credit_card_payment_return: 'إرجاع مدفوعات بطاقة الائتمان',
			credit_card_receipt: 'إيصال بطاقة الائتمان',
			current: 'حاضِر',
			current_password: 'كلمة السر الحالية',
			current_wallet: 'المحفظة الحالية',
			dashboard: 'لوحة التحكم',
			data_added: 'البيانات المضافة',
			data_saved: 'البيانات المحفوظة',
			datas: 'بيانات',
			date: 'تاريخ',
			date_first_installment: 'تاريخ أول دفعة',
			deb_de_trader: 'ديون التاجر',
			deb_game_donate: 'لعبة التبرع بالخصم',
			deb_saldo: 'الرصيد المدين',
			deb_saldo_disp: 'الرصيد المتاح للخصم',
			deb_val_indev: 'مبلغ الخصم غير لائق',
			debit_card_payment: 'الدفع ببطاقة الخصم',
			debit_card_payment_return: 'إرجاع الدفع ببطاقة الخصم',
			debit_card_receipt: 'إيصال بطاقة الخصم',
			debt: 'دَين',
			debt_td: 'دَين',
			December: 'ديسمبر',
			decImpRenda: 'إعلان ضريبة الدخل',
			deleted_document: 'تم حذف المستند من السجل الخاص بك',
			department: 'قسم',
			dependents: 'المعالون',
			desc: 'إلى أسفل',
			description: 'وصف',
			details: 'تفاصيل',
			dev_econom: 'النمو الإقتصادي',
			developer: 'مطور',
			directly_indicated: 'يشار مباشرة',
			directly_indicateds: 'عين مباشرة',
			directs: 'مباشر',
			district: 'الحي / الحي',
			divorced: 'مُطلّق',
			doa_men_receb: 'تم استلام التبرع الشهري للمنصة',
			doacao_td: 'هبة',
			doctorate_complete: 'دكتوراة - كاملة',
			doctorate_incomplete: 'الدكتوراه - غير مكتمل',
			document: 'وثيقة',
			document_already_registered: 'تم تسجيل المستند بالفعل',
			documents: 'وثائق',
			donate: 'يتبرع',
			donor: 'جهات مانحة',
			dont_know_zip: 'لا تعرف الرمز البريدي؟ انقر هنا',
			dou_fe: 'أؤكد أن المعلومات الواردة أعلاه صحيحة.',
			downloads: 'تحميل ملفات',
			drop_images: 'انقر أو اسحب الصور هنا',
			due_date: 'نضج',
			edit: 'للتعديل',
			edit_option: 'خيار التحرير',
			edit_question: 'تحرير السؤال',
			email: 'بريد إلكتروني',
			email_already_registered: 'البريد الإلكتروني مسجل مسبقا',
			email_and_or_password_wrong: 'بريد إلكتروني و / أو كلمة مرور خاطئة!',
			email_for_login: 'سيكون هذا البريد الإلكتروني هو تسجيل الدخول الخاص بك',
			email_or_password_wrong: 'بريد أو كلمة مرورغير صحيحة',
			employee: 'موظف',
			employees: 'موظفين',
			empty: 'فارغ',
			entrada: 'هل لها قيمة دخول؟ إذا كانت الإجابة بنعم ، فما هي القيمة',
			entries_until_today: 'إدخالات حتى الآن',
			error_confirm_password: 'يجب أن يكون التأكيد وكلمة المرور الجديدة متطابقتين',
			error_email: 'تنسيق بريد إلكتروني غير صالح',
			error_password: 'كلمة سر خاطئة',
			error_phone: 'رقم الهاتف غير صحيح',
			estadoInteresse: 'ولاية',
			exchange: 'إستبدال',
			expired: 'منتهي الصلاحية',
			extorno_td: 'يعود',
			extra: 'إضافي',
			extract: 'يستخرج',
			extracts: 'مقتطفات',
			fantasy: 'خيالي',
			fatherName: 'اسم الاب',
			February: 'شهر فبراير',
			fee: 'رسوم الانسحاب',
			feedback: 'يعود',
			feminine: 'المؤنث',
			fgts: 'قيمة FGTS الخاص بك',
			field_required: 'حقل الزامي',
			final: 'أخير',
			finance: 'مالي',
			financial: 'مالي',
			financial_payment: 'الدفع المالي',
			financial_payment_return: 'عودة السداد المالي',
			financial_receipt: 'إيصال مالي',
			financing: 'التمويل',
			financings: 'التمويل',
			first_10_pages: 'أول 10 صفحات',
			fisica: 'شخص مادي',
			for_year: 'بالسنة',
			forget_password: 'لقد نسيت كلمة المرور',
			forgot_password: 'هل نسيت كلمة السر',
			form: 'استمارة',
			form_of_payment: 'طريقة الدفع',
			found: 'وجد',
			founds: 'وجد',
			friend_indicated: 'تم ترشيحها من قبل أصدقائي',
			friend_indicateds: 'تم ترشيحها من قبل أصدقائي',
			fuel: 'وقود',
			full_name: 'الاسم الكامل',
			funcPublico: 'هل انت موظف عمومي؟',
			fundamental_complete: 'الابتدائية - كاملة',
			fundamental_incomplete: 'ابتدائي - غير مكتمل',
			ganh_diario: 'الأرباح اليومية',
			gender: 'جنس',
			grand_total: 'المجموع الإجمالي',
			group: 'مجموعة',
			has_withdraw_today: 'تم بالفعل طلب سحب اليوم',
			home: 'يبدأ',
			how_it_works: 'كيف تعمل',
			how_to_call: 'ماذا تريد ان تسمى؟',
			id_td: 'إشارة مباشرة',
			images: 'الصور',
			in_construction: 'تحت التشيد',
			in_order: 'مرتب',
			in_stock: 'في الأوراق المالية',
			indicant: 'مؤشر',
			indicated_by: 'يتم ترشيحك من قبل',
			indicated_not_found: 'لم يتم العثور على المؤشر المشار إليه ...',
			indicateds: 'مبين',
			indirects: 'غير مباشر',
			info: 'النشرات الإخبارية',
			info_contact: 'معلومات الاتصال',
			initial: 'أولي',
			insert_new: 'أدخل جديد',
			installments: 'أقساط',
			intelligence: 'ذكاء',
			interesse: 'ما هي منطقة الاهتمام',
			invalid_contact: 'تنسيق جهة الاتصال غير صالح',
			invalid_images: 'صور غير صالحة',
			invalid_token: 'رمز غير صالح',
			invite: 'للدعوة',
			invite_friends: 'اعزم أصحابك',
			January: 'يناير',
			July: 'يوليو',
			June: 'يونيو',
			juridica: 'شخص قانوني',
			know_exact_result: 'هل تعرف القيمة الدقيقة؟',
			lead: 'معا سنبني مستقبلا جديدا.',
			learn_more: 'تعرف أكثر',
			left: 'غادر',
			left_preferential: 'تفضل الجانب الأيسر',
			legalNature: 'الطبيعة القانونية',
			level: 'مستوى',
			limite_excedido: 'تم تجاوز حد الخصم',
			linear: 'خطي',
			link_email: 'انقر فوق الارتباط المرسل إلى البريد الإلكتروني المسجل!',
			link_invite_copy: 'تم نسخ رابط المشاركة بنجاح',
			liquid: 'سائل',
			list: 'قائمة',
			loading: 'تحميل',
			location: 'موقع',
			login: 'للدخول',
			logout: 'للخروج',
			male: 'مذكر',
			manage_users: 'ادارة المستخدمين',
			manager: 'مدير',
			mandala: 'ماندالا',
			mandalas: 'ماندالا',
			manufacturer: 'الصانع',
			March: 'يمشي',
			maritalStatus: 'الحالة الاجتماعية',
			market: 'سوق',
			married: 'متزوج',
			master_complete: 'ماجستير - كامل',
			master_incomplete: 'ماجستير - غير مكتمل',
			May: 'يمكن',
			message: 'رسالة',
			messageNotSent: 'لم يكن من الممكن إرسال رسالتك',
			messageSent: 'تم ارسال رسالتك بنجاح',
			middle_complete: 'متوسطة - كاملة',
			middle_incomplete: 'متوسطة - غير كاملة',
			min_amount_error: 'يجب أن تكون القيمة على الأقل:',
			min_withdraw: 'المبلغ الأدنى للسحب',
			missing: 'مفتقد',
			mission: 'مهمة',
			mission_vision_values: 'الرسالة والرؤية والقيم',
			model: 'نموذج',
			monitors: 'الشاشات',
			monthly: 'الدفع الشهري',
			motherName: 'اسم الأم',
			movements: 'الحركات',
			msg_renovacao: 'لقد وصلت إلى الحد الأقصى للكسب ، قم بتجديد باقتك.',
			my_account: 'حسابي',
			my_wallet: 'محفظتى',
			mystock: 'مخزوني',
			name: 'اسم',
			nascMaisVelhoRenda2: 'ما هو تاريخ الميلاد الاقدم؟',
			nationality: 'الجنسية (بلد الميلاد)',
			naturalness: 'مكان الميلاد (المدينة التي ولدت فيها)',
			nature: 'طبيعة',
			network: 'شبكة',
			new: 'جديد',
			new_call: 'اتصال جديد',
			new_financing: 'تمويل جديد',
			new_order: 'طلب جديد',
			new_password: 'كلمة المرور الجديدة',
			new_password_security: 'كلمة مرور أمان جديدة',
			new_seller: 'بائع جديد',
			new_wallet: 'محفظة جديدة',
			news: 'أخبار',
			next: 'التالي',
			nick: 'مستخدم',
			nis: 'PIS / شيكل',
			nis_already_registered: 'تم تسجيل PIS / NIS بالفعل',
			no: 'لا',
			no_approve: 'لا تزال غير موافق',
			no_delete: 'لا تستبعد',
			no_literate: 'لا يعرف القراءة والكتابة',
			no_repeat: 'لا يمكن تكرار البيانات',
			no_results_found: 'لم يتم العثور على نتائج',
			no_services_or_products_added: 'لا توجد خدمات أو منتجات مضافة',
			no_settings: 'انتقل إلى الإعدادات واملأ التفاصيل الخاصة بك.',
			none_friends_indicated: 'لم أحيل أي أصدقاء حتى الآن',
			not_authorized: 'غير مخول',
			not_found: 'غير معثور عليه',
			not_login: 'لم يكن من الممكن الدخول والتحقق من البيانات وحاول مرة أخرى!',
			not_orders: 'لا توجد حوالات مالية',
			not_permission: 'ليس لديك إذن للوصول إلى هذه الصفحة.',
			not_registered: 'لم يتم تسجيلة بعد؟ انقر هنا وقم بالتسجيل',
			not_results: 'لا توجد سجلات',
			not_self_register: 'غير قادر على التسجيل',
			not_today_entry: 'ما زلنا لم نحصل على تذاكر اليوم',
			notfound_ad: 'لا توجد إعلانات',
			November: 'شهر نوفمبر',
			number: 'رقم',
			obs: 'تعليقات',
			October: 'اكتوبر',
			of: 'في',
			off: 'محيت',
			on_total: 'من المجموع الكلي',
			open: 'يفتح',
			opening: 'افتتاح',
			operation: 'أداء',
			ordenation: 'سيامة',
			order: 'طلب',
			order_not_found: 'الطلب غير موجود',
			orders: 'الطلبات',
			other: 'آخر',
			other_services_or_products: 'خدمات أو منتجات أخرى',
			paid: 'دفع',
			paid_date: 'يوم الدفع',
			partial: 'المدفوعة جزئيا',
			passport: 'جواز سفر',
			password: 'كلمة المرور',
			password_security: 'كلمة المرور الأمن',
			pay: 'يدفع',
			pay_generate: 'توليد الدفع',
			pay_selected: 'الدفع المحدد',
			pay_value_in: 'دفع المبلغ في',
			payable: 'يدفع',
			payer: 'دافع',
			payment: 'قسط',
			ped_retirada: 'طلب سحب',
			ped_retirada_gan_diario: 'طلب سحب الأرباح اليومية',
			pendent: 'قيد الانتظار',
			people: 'الناس',
			person: 'شخص',
			person_found_in_other_prefecture: 'شخص مسجل بالفعل في بلدية أخرى',
			personal: 'شباب',
			personal_addresses: 'العناوين',
			personal_contacts: 'جهات الاتصال',
			personal_data: 'بيانات شخصية',
			personal_infos: 'معلومة',
			personal_pcva: 'برنامج البيت الأخضر والأصفر',
			phone: 'هاتف',
			pix: 'بيكس',
			pix_payment: 'الدفع في PIX',
			pix_payment_return: 'عودة المدفوعات في PIX',
			pix_receipt: 'الاستلام في PIX',
			pl_carreira: 'المسار المهني',
			plan: 'مستوي',
			plates: 'لوحات',
			please_wait: 'انتظر من فضلك',
			plus: 'زائد',
			point: 'نقطة',
			points: 'نقاط',
			portfolio: 'مَلَفّ',
			pos_complete: 'Lato Sensu - كاملة',
			pos_incomplete: 'الحس اللاتيني - غير مكتمل',
			prefecture: 'بلدية',
			prefecture_addresses: 'العناوين',
			prefecture_contacts: 'جهات الاتصال',
			prefecture_data: 'بيانات قاعة المدينة',
			prefecture_infos: 'معلومة',
			prefectures: 'قاعات المدينة',
			prev: 'سابق',
			price: 'سعر',
			print: 'اطبع',
			privacy_policies: 'سياسة الخصوصية',
			private_works: 'الأشغال الخاصة',
			profession: 'مهنة',
			profile: 'حساب تعريفي',
			program: 'برنامج',
			programs: 'برمجة',
			project: 'مشروع',
			projects: 'المشاريع',
			public_works: 'الأشغال العامة',
			purchase: 'شراء',
			qualify_binary: 'التأهيل الثنائي',
			qualquer: 'أي',
			quantity: 'كمية',
			quotation: 'سعر',
			read: 'ليقرأ',
			receivable: 'لاستقبال',
			recipient: 'المتلقي',
			recommendations: 'التوصيات',
			redefine_password: 'أعد تعريف كلمة المرور',
			register: 'سِجِلّ',
			register_people: 'تسجيل الناس',
			register_wallet: 'قم بتسجيل محفظة BTC لإجراء عمليات السحب',
			registers: 'السجلات',
			registers_users: 'تسجيل المستخدم',
			reject: 'يرفض',
			remaining: 'متبقي',
			remove: 'لازالة',
			rendaIndividual: 'الدخل الإجمالي الفردي',
			renew: 'تجديد',
			renew_mandala: 'تجديد ماندالا',
			reply: 'ليجيب',
			reports: 'التقارير',
			required_data_not_sent: 'لم يتم إرسال البيانات المطلوبة',
			resend_email: 'إعادة إرسال البريد الإلكتروني',
			residential: 'سكني',
			responded: 'أجاب',
			restricao: 'هل عندك قيد في اسمك؟ (SPC / Serasa)',
			result: 'نتيجة',
			results: 'نتائج',
			resume_data: 'ملخص البيانات',
			revoked: 'إلغاء - فسخ',
			rg: 'التسجيل العام (الهوية)',
			rgAgency: 'مُصدر RG',
			rgDate: 'تاريخ إصدار RG',
			rgUf: 'حالة الهوية',
			right: 'يمين',
			right_preferential: 'تفضل الجانب الأيمن',
			roi: 'الأرباح اليومية',
			sale: 'أُوكَازيُون',
			saq_duplicado: 'سحب مكرر',
			save: 'للحفظ',
			schooling: 'تعليم',
			search: 'يبحث',
			secretary: 'سكرتير',
			security_password: 'كلمة المرور الأمن',
			see: 'لترى',
			see_advents: 'رؤية الإعلانات',
			see_call: 'انظر الاتصال',
			see_details: 'انظر التفاصيل',
			see_details_wallet: 'عرض تفاصيل المحفظة',
			see_financing: 'انظر التمويل',
			see_order: 'مشاهدة الطلب',
			see_user: 'عرض المستخدمين',
			select_vehicle: 'اختر السيارة',
			self_register: 'يسجل',
			seller: 'تاجر',
			send_call: 'إرسال التذكرة',
			send_to: 'ارسل إلى',
			September: 'سبتمبر',
			settings: 'إعدادات',
			share_your_link: 'شارك الرابط الخاص بك',
			show: 'تظهر ل',
			show_from: 'يظهر من',
			side: 'جانب',
			sign_in_to_start_your_session: 'تسجيل الدخول لبدء الجلسة الخاصة بك',
			sign_up: 'يسجل',
			single: 'أعزب',
			sold: 'مُباع',
			state: 'ولاية',
			status: 'حالة',
			strategies: 'الاستراتيجيات',
			street: 'طريق',
			subject: 'موضوع',
			submit: 'لترسل',
			submit_placeholder: 'يرجى ملء البريد الإلكتروني الخاص بك',
			subscribe: 'اشترك لتلقي أخبارنا',
			subtitle: 'التسمية التوضيحية',
			success_update_user: 'تم تحديث المستخدم بنجاح!',
			success_wallet: 'تم تحديث المحفظة بنجاح!',
			suggestions: 'اقتراحات',
			superior_complete: 'تخرج',
			superior_incomplete: 'أعلى غير مكتمل',
			supervisor: 'مشرف',
			support: 'يدعم',
			support_materials: 'مواد الدعم',
			suspended: 'معلق',
			symbols: 'حرف او رمز',
			system: 'نظام',
			target: 'هدف',
			tempoCarteira: 'منذ متى كان السجل في المحفظة',
			terms: 'أقبل شروط الاستخدام',
			this_value_in: 'هذه القيمة في',
			title: 'عنوان',
			to: 'حتى',
			to_pay: 'لإيقاف',
			to_receive: 'مستحق',
			today_entry: 'دخول اليوم',
			token_not_found_or_already_used: 'لم يتم العثور على رمز أو استخدامه بالفعل',
			total: 'المجموع',
			total_entry: 'إجمالي الدخول',
			traderbot: 'تاجر الروبوت',
			transf_credito: 'تحويل الرصيد',
			transf_debito: 'التحويل المدين',
			transfer_payment: 'دفع التحويل',
			transfer_payment_return: 'تحويل إرجاع الدفع',
			transfer_receipt: 'إيصال في التحويل',
			transparency: 'الشفافية',
			tx_adm_cred: 'إدارة الضرائب',
			tx_adm_deb: 'إدارة الضرائب',
			tx_retirada: 'رسوم الانسحاب',
			tx_retirada_gan_diario: 'معدل سحب الأرباح اليومية',
			type: 'يكتب',
			update: 'للتحديث',
			update_wallet: 'تحديث المحفظة',
			upgrade: 'تحسين',
			used: 'مستخدم',
			user: 'مستخدم',
			user_active: 'مستخدم نشط',
			user_already_exists: 'المستخدم موجود اصلا!',
			user_and_or_password_wrong: 'اسم المستخدم و / أو كلمة المرور خاطئة!',
			user_inactive: 'مستخدم غير نشط',
			user_not_found: 'لم يتم العثور على المستخدم',
			user_not_logged: 'المستخدم لم يسجل الدخول',
			user_or_password_wrong: 'اسم المستخدم أو كلمة المرور غير صحيحة',
			users: 'المستخدمون',
			users_list: 'قائمة المستخدم',
			valid_images: 'صور صالحة',
			valorRenda2: 'ما قيمة الدخل الثاني',
			value: 'قيمة',
			value_donate_on: 'قيمة التبرع في',
			value_financed: 'المبلغ الممول',
			value_installment: 'قيمة القسط',
			values: 'قيم',
			vehicle: 'عربة',
			vehicles: 'مركبات',
			view_order: 'انظر الطلب',
			vision: 'رؤية',
			visitor: 'زائر',
			voucher: 'فاتورة',
			wait_confirm: 'في انتظار التأكيد',
			waiting: 'منتظر',
			waiting_email: 'تم التسجيل بنجاح ، والآن كل ما تبقى هو تأكيد البريد الإلكتروني.',
			waiting_payment: 'انتظار الدفع',
			wallet: 'مَلَفّ',
			wallet_generate: 'إنشاء المحفظة',
			wallets: 'محافظ',
			want_donate: 'اريد التبرع',
			want_now_more_about: 'اريد معرفة المزيد عن',
			warning: 'انتباه',
			we_are: 'نكون',
			webhooks: 'webhooks',
			welcome: 'مرحباً',
			who_we_are: 'من نحن',
			who_we_are_subtitle: 'وصف موجز عن',
			widower: 'أرمل',
			will_expire_in: 'ستنتهي في',
			with: 'مع',
			withdraw: 'انسحاب',
			withdraw_sended_email: 'طلب سحب ، تحقق من بريدك الإلكتروني وانقر على الرابط لتفويض!',
			withdrawal: 'الانسحابات',
			withdrawals: 'الانسحابات',
			year: 'سنة',
			years: 'سنين',
			yes: 'نعم',
			your_country: 'بلدك',
			your_dashboard: 'هذه هي لوحة التحكم الخاصة بك.',
			your_link: 'وصلتك',
			zipcode: 'الرمز البريدي',
			amount_above_balance: 'توازن غير كاف',
			symbol: 'رمز',
			close: 'إنهاء',
			high: 'ألتو',
			low: 'قليل',
			order_book: 'سجل الطلبيات',
			coin: 'عملة',
			free: 'كتاب',
			locked: 'محظور',
			you_have: 'لديك',
			buy_volume: 'شراء حجم',
			sell_volume: 'حجم البيع',
			estimate: 'مُقدَّر',
			photo: 'صورة',
			telegram_chat_id: 'معرف دردشة Telegram',
			pendent_order: 'لديك أوامر معلقة',
			active_automations: 'أتمتة نشطة',
			active_monitors: 'شاشات نشطة',
			active_users: 'المستخدمين النشطين',
			active_connections: 'اتصالات نشطة',
			ticker_health: 'صحة المعدة',
			book_health: 'كتاب الصحة',
			enter_your_new_password: 'أدخل كلمة المرور الجديدة',
			confirm_your_new_password: 'أدخل كلمة المرور الجديدة مرة أخرى',
			alerts: 'تحذير',
			alert: 'يُحذًِر',
			telegram: 'Telegram',
			logs: 'السجلات',
			release_withdrawals: 'الإفراج عن السحب',
			bot_active: 'السماح لوب',
			available: 'متاح',
		},
	},
};

export { messages };
