import axios from './BaseService';

const WITHDRAWALS_URL = `${process.env.REACT_APP_API_URL}/withdraws/`;

export async function getWithdrawals(token, page, limit, search) {
	const endPoint = `${WITHDRAWALS_URL}list${page ? `?page=${page}` : ''}`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { limit, search }, { headers });
	return response.data;
}

export async function payWithdraw(token, withdrawals, coin) {
	const endPoint = `${WITHDRAWALS_URL}payWithdraw`;
	const headers = { 'Content-Type': 'application/json', Authorization: `${token}` };
	const response = await axios.post(endPoint, { withdrawals, coin }, { headers });
	return response.data;
}
