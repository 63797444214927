import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { doLogout } from '../../services/AuthService';
import SideBarItem from './SideBarItem';
import { i18n } from '../../utils/translates/i18n';
import Translate from '../../utils/translates/components/Translate';

function SideBar() {
	const history = useHistory();

	function cleanAndRedirect() {
		sessionStorage.removeItem('token');
		history.push('/');
	}

	function onLogoutClick(event) {
		doLogout()
			.then(response => cleanAndRedirect())
			.catch(error => {
				console.error(error);
				cleanAndRedirect();
			});
	}

	return (
		<nav id='sidebarMenu' className='sidebar d-lg-block bg-gray-800 text-white collapse' datasimplebar='true'>
			<div className='sidebar-inner px-4 pt-3'>
				<div className='user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4'>
					<div className='collapse-close d-md-none'>
						<a
							href='#sidebarMenu'
							data-bs-toggle='collapse'
							data-bs-target='#sidebarMenu'
							aria-controls='sidebarMenu'
							aria-expanded='true'
							aria-label='Toggle navigation'>
							<svg className='icon icon-xs' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
								<path
									fillRule='evenodd'
									d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
									clipRule='evenodd'></path>
							</svg>
						</a>
					</div>
				</div>
				<ul className='nav flex-column pt-3 pt-md-0'>
					<li className='nav-item'>
						<Link to='/dashboard' className='nav-link d-flex align-items-center'>
							<span className='sidebar-icon'>
								<img src='/logos/favicon.png' alt='Logo' />
							</span>
							<span className='mt-1 ms-1 sidebar-text'>{process.env.REACT_APP_NAME}</span>
						</Link>
					</li>
					<SideBarItem to='/dashboard' text={i18n.t('dashboard')}>
						<svg
							className='icon icon-xs me-2'
							fill='currentColor'
							viewBox='0 0 20 20'
							xmlns='http://www.w3.org/2000/svg'>
							<path d='M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z'></path>
							<path d='M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z'></path>
						</svg>
					</SideBarItem>
					<SideBarItem to='/users' text={i18n.t('users')}>
						<svg
							className='icon icon-xs me-2'
							fill='currentColor'
							viewBox='0 0 20 20'
							xmlns='http://www.w3.org/2000/svg'>
							<path d='M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z' />
						</svg>
					</SideBarItem>
					<SideBarItem to='/orders' text={i18n.t('orders')}>
						<svg
							className='icon icon-xs me-2'
							fill='currentColor'
							viewBox='0 0 20 20'
							xmlns='http://www.w3.org/2000/svg'>
							<path d='M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z'></path>
							<path
								fillRule='evenodd'
								d='M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z'
								clipRule='evenodd'></path>
						</svg>
					</SideBarItem>
					<SideBarItem to='/withdrawals' text={i18n.t('withdrawals')}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='icon icon-xs me-2'
							fill='currentColor'
							viewBox='0 0 24 24'
							strokeWidth='1.5'
							stroke='currentColor'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
							/>
						</svg>
					</SideBarItem>
					<SideBarItem to='/calls' text={i18n.t('calls')}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth='1.5'
							stroke='currentColor'
							className='icon icon-xs me-2'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155'
							/>
						</svg>
					</SideBarItem>

					<li className='nav-item'>
						<span
							className='nav-link collapsed d-flex justify-content-between align-items-center'
							data-bs-toggle='collapse'
							data-bs-target='#submenu-settings'>
							<span>
								<span className='sidebar-icon'>
									<svg
										className='icon icon-xs me-2'
										fill='currentColor'
										viewBox='0 0 20 20'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fillRule='evenodd'
											d='M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z'
											clipRule='evenodd'></path>
									</svg>
								</span>
								<span className='sidebar-text'>{i18n.t('settings')}</span>
							</span>
							<span className='link-arrow'>
								<svg
									className='icon icon-sm'
									fill='currentColor'
									viewBox='0 0 20 20'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										fillRule='evenodd'
										d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
										clipRule='evenodd'></path>
								</svg>
							</span>
						</span>
						<div className='multi-level collapse' role='list' id='submenu-settings'>
							<ul className='flex-column nav'>
								<SideBarItem to='/settings' text={i18n.t('system')} />
								<SideBarItem to='/symbols' text={i18n.t('symbols')} />
								<SideBarItem to='/limits' text={i18n.t('traderbot')} />
								<SideBarItem to='/telegram-chat' text={i18n.t('telegram')} />
								<SideBarItem to='/logs' text={i18n.t('logs')} />
							</ul>
						</div>
					</li>
					<Translate />
					<li role='separator' className='dropdown-divider mt-4 mb-3 border-gray-700'></li>
					<SideBarItem to='/' text={i18n.t('logout')} onClick={onLogoutClick}>
						<svg
							className='icon icon-xs me-2'
							fill='none'
							stroke='currentColor'
							viewBox='0 0 20 20'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'></path>
						</svg>
					</SideBarItem>
				</ul>
			</div>
		</nav>
	);
}

export default SideBar;
