import React from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import Login from './public/Login/Login';
import Dashboard from './private/Dashboard/Dashboard';
import Users from './private/Users/Users';
import Orders from './private/Orders/Orders';
import Limits from './private/Limits/Limits';
import Settings from './private/Settings/Settings';
import Logs from './private/Logs/Logs';
import Symbols from './private/Symbols/Symbols';
import TelegramChat from './private/Users/TelegramChat';
import Withdrawals from './private/Withdrawals/Withdrawals';
import Calls from './private/Calls/Calls';
import ReadCall from './private/Calls/ReadCall';

function Routes() {
	function PrivateRoute({ children, ...rest }) {
		return (
			<Route
				{...rest}
				render={() => {
					return sessionStorage.getItem('token') ? children : <Redirect to='/' />;
				}}
			/>
		);
	}

	return (
		<BrowserRouter>
			<Route path='/' exact>
				<Login />
			</Route>
			<PrivateRoute path='/dashboard'>
				<Dashboard />
			</PrivateRoute>
			<PrivateRoute path='/settings'>
				<Settings />
			</PrivateRoute>
			<PrivateRoute path='/symbols'>
				<Symbols />
			</PrivateRoute>
			<PrivateRoute path='/logs'>
				<Logs />
			</PrivateRoute>
			<PrivateRoute path='/users'>
				<Users />
			</PrivateRoute>
			<PrivateRoute path='/orders'>
				<Orders />
			</PrivateRoute>
			<PrivateRoute path='/withdrawals'>
				<Withdrawals />
			</PrivateRoute>
			<PrivateRoute path='/calls'>
				<Calls />
			</PrivateRoute>
			<PrivateRoute path='/readcall'>
				<ReadCall />
			</PrivateRoute>
			<PrivateRoute path='/limits'>
				<Limits />
			</PrivateRoute>
			<PrivateRoute path='/telegram-chat'>
				<TelegramChat />
			</PrivateRoute>
		</BrowserRouter>
	);
}

export default Routes;
