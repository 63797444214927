const messages = {
	de: {
		translations: {
			a_crase: 'Der',
			about: 'Über uns',
			above_the_same: 'Oben genau das gleiche',
			action: 'Aktion',
			actionTemplates: 'Lagermodelle',
			activate_date: 'Aktivierungs-Datum',
			ad: 'Bekanntmachung',
			add: 'Beitritt',
			add_option: 'Option hinzufügen',
			add_payment: 'Zahlung hinzufügen',
			add_question: 'Frage hinzufügen',
			add_receipt: 'Quittung hinzufügen',
			add_wallet: 'Geldbörse hinzufügen',
			address: 'Adresse',
			admin: 'Verwaltung',
			advance: 'Vorauszahlung',
			affected: 'erreicht',
			after: 'Nach',
			air_transport: 'Lufttransport',
			ajust_limite: 'Limit-Anpassung',
			ajust_saldo: 'Balance-Anpassung',
			all: 'Alle',
			all_departments: 'Alle Abteilungen',
			allies_of: 'Verbündete von',
			allRightsReserved: 'Alle Rechte vorbehalten.',
			almost_there: 'Fast dort',
			already_offset: 'bereits kompensiert',
			already_registered: 'Bereits registriert? Klicken Sie hier und geben Sie ein',
			amount: 'Wert',
			and: 'Es ist',
			and_more: 'und mehr',
			answer: 'Antwort',
			ao: 'zum',
			approve: 'Zu genehmigen',
			April: 'April',
			asc: 'Aufsteigend',
			at: 'In',
			August: 'August',
			automations: 'Automatisierungen',
			awaiting_email_confirmation: 'Warte auf E-Mail-Bestätigung!',
			b_order: 'Befehl',
			b_orders: 'Aufträge',
			back: 'Zurück zu gehen',
			bairroInteresse: 'Nachbarschaft',
			balance: 'Gleichgewicht',
			balance_available: 'Guthaben vorhanden',
			balance_network: 'Netzwerkbilanz',
			banned: 'Verboten',
			before_contact: 'Bevor Sie uns kontaktieren',
			binary: 'Binär',
			birth: 'Geburt',
			blind_man: 'umgedreht',
			body_monthly:
				'Ihre monatliche Gebühr ist abgelaufen und Ihr BOT TRADER wurde deaktiviert, bitte bezahlen Sie die Zahlungsanweisung und genießen Sie Ihre Vorteile erneut',
			bonus_saldo_trader: 'Trader-Guthabenbonus',
			but_possible_register:
				'Trotzdem ist es möglich, sich zu registrieren, aber es wird nicht mit einem werbenden Freund in Verbindung gebracht.',
			buyer: 'Käufer',
			by: 'pro',
			bye: 'Bis später',
			call: 'Genannt',
			call_to: 'Ruf an',
			calls: 'genannt',
			cancel: 'Stornieren',
			canceled: 'Abgesagt',
			career_plan: 'Karriereweg',
			casa_verde_amarela: 'Gelbgrünes Haus',
			cash_payment: 'Barzahlung',
			cash_payment_return: 'Rückzahlung der Barzahlung',
			cash_receipt: 'Geldeingang',
			change_indicant: 'Anzeige ändern',
			change_photo: 'Foto ändern',
			change_plan: 'Plan ändern',
			check_payment: 'Zahlung per Scheck',
			check_payment_return: 'Rückzahlung per Scheck',
			check_receipt: 'Erhalt per Scheck',
			choose: 'Wählen',
			cidadeInteresse: 'Stadt',
			city: 'Stadt',
			claims: 'Ansprüche',
			clean: 'Reinigen',
			click_and_know: 'Klicken und wissen',
			click_to_copy_the_wallet: 'Klicken Sie hier, um Portfolio zu kopieren',
			client: 'Klient',
			clients: 'Kunden',
			cnh: 'Führerschein (Führerschein)',
			cnhDate: 'Ausstellungsdatum des Führerscheins',
			cnpj: 'CNPJ',
			cnpj_invalid: 'Ungültiger CNPJ',
			color: 'Farbe',
			commercial: 'Werbung',
			company: 'Name der Firma',
			complement: 'Ergänzen',
			comporRenda: 'Gehen Sie mit jemandem Geld verdienen',
			compRenda: 'Art des Einkommensnachweises',
			confirm_approve: 'Zulassung bestätigen',
			confirm_delete: 'Löschung bestätigen',
			confirm_new_password: 'Bestätige neues Passwort',
			confirm_new_password_security: 'Bestätigen Sie das neue Sicherheitskennwort',
			confirm_password: 'Zugangspasswort bestätigen',
			confirm_password_security: 'Bestätigen Sie das Sicherheitskennwort',
			confirmed: 'Bestätigt',
			confirmed_email: 'bestätigte E-Mail',
			congratulations: 'Glückwunsch',
			contact: 'Kontakt',
			contract: 'Vertrag',
			copied: 'kopiert',
			copy: 'Kopieren',
			copy_success: 'Erfolgreich kopiert!',
			correspondences: 'Korrespondenz',
			countries: 'Länder',
			country: 'Land',
			cpf: 'CPF',
			cpf_invalid: 'Ungültige CPF',
			create_order_success: 'Bestellung erfolgreich erstellt!',
			created: 'Registriert in',
			cred: 'Kredit',
			cred_binario: 'Binärer Kredit',
			cred_deb: 'Gutschriften/Belastungen',
			cred_game_donate: 'Spiel Guthaben spenden',
			cred_ind_direta: 'Indirekter Empfehlungskredit',
			cred_ind_indireta: 'Gutschrift für direkte Empfehlung',
			cred_mandala: 'Mandala-Kredit',
			cred_pagar_com_saldo: 'Kredit bezahlen mit Guthaben',
			cred_pontos: 'Kreditpunkte',
			cred_recb_trader: 'Gutschrift erhalten Händler',
			cred_renovacao: 'Verlängerungskredit',
			cred_saldo_disp: 'Verfügbares Guthaben',
			cred_upgrade: 'Guthaben aufwerten',
			credit_card_payment: 'Kreditkarten Zahlung',
			credit_card_payment_return: 'Kreditkartenzahlung zurück',
			credit_card_receipt: 'Kreditkartenbeleg',
			current: 'Aktuell',
			current_password: 'Aktuelles Passwort',
			current_wallet: 'Aktuelles Portfolio',
			dashboard: 'Schalttafel',
			data_added: 'Daten hinzugefügt',
			data_saved: 'Gespeicherte Daten',
			datas: 'Daten',
			date: 'Datum',
			date_first_installment: 'Erster Zahlungstermin',
			deb_de_trader: 'Händlerschulden',
			deb_game_donate: 'Spiel Donate Debit',
			deb_saldo: 'Sollsaldo',
			deb_saldo_disp: 'Verfügbarer Sollsaldo',
			deb_val_indev: 'Unzulässiger Betrag belasten',
			debit_card_payment: 'Debitkartenzahlung',
			debit_card_payment_return: 'Rückgabe der Debitkartenzahlung',
			debit_card_receipt: 'Debitkartenbeleg',
			debt: 'Lastschrift',
			debt_td: 'Schulden',
			December: 'Dezember',
			decImpRenda: 'Einkommensteuer erklären',
			deleted_document: 'Das Dokument wurde aus Ihrem Register gelöscht',
			department: 'Abteilung',
			dependents: 'Angehörige',
			desc: 'Nach unten',
			description: 'Beschreibung',
			details: 'Einzelheiten',
			dev_econom: 'Wirtschaftliche Entwicklung',
			developer: 'Entwickler',
			directly_indicated: 'direkt angegeben',
			directly_indicateds: 'Direkt ernannt',
			directs: 'Direkte',
			district: 'Nachbarschaft / Bezirk',
			divorced: 'Geschieden',
			doa_men_receb: 'Erhaltene monatliche Plattformspende',
			doacao_td: 'Spende',
			doctorate_complete: 'Promotion - abgeschlossen',
			doctorate_incomplete: 'Promotion - Unvollständig',
			document: 'Dokumentieren',
			document_already_registered: 'Dokument bereits registriert',
			documents: 'Unterlagen',
			donate: 'Spenden',
			donor: 'Spender',
			dont_know_zip: 'Sie kennen die Postleitzahl nicht? klicken Sie hier',
			dou_fe: 'Ich bestätige die Richtigkeit der vorstehenden Angaben.',
			downloads: 'Dateien herunterladen',
			drop_images: 'Klicken oder ziehen Sie die Bilder hierher',
			due_date: 'Reife',
			edit: 'Bearbeiten',
			edit_option: 'Bearbeitungsoption',
			edit_question: 'Frage bearbeiten',
			email: 'Email',
			email_already_registered: 'Email schon registriert',
			email_and_or_password_wrong: 'Falsche E-Mail und/oder Passwort!',
			email_for_login: 'Diese E-Mail wird Ihr Login sein',
			email_or_password_wrong: 'falsche Email oder Passwort',
			employee: 'Mitarbeiter',
			employees: 'Mitarbeiter',
			empty: 'Leer',
			entrada: 'Hat es einen Einstiegswert? Wenn JA, welcher Wert',
			entries_until_today: 'Bisherige Einträge',
			error_confirm_password: 'Bestätigung und Neues Passwort müssen identisch sein',
			error_email: 'Ungültiges Email-Format',
			error_password: 'Falsches Passwort',
			error_phone: 'Ungültige Telefonnummer',
			estadoInteresse: 'Zustand',
			exchange: 'Ersatz',
			expired: 'Abgelaufen',
			extorno_td: 'zurückkehren',
			extra: 'Extra',
			extract: 'Extrakt',
			extracts: 'Auszüge',
			fantasy: 'Fantasie',
			fatherName: 'Name des Vaters',
			February: 'Februar',
			fee: 'Abhebegebühr',
			feedback: 'Zurückkehren',
			feminine: 'Feminin',
			fgts: 'Wert Ihres FGTS',
			field_required: 'Pflichtfeld',
			final: 'Finale',
			finance: 'Finanziell',
			financial: 'Finanziell',
			financial_payment: 'finanzielle Zahlung',
			financial_payment_return: 'Rückgabe der finanziellen Zahlung',
			financial_receipt: 'finanzielle Quittung',
			financing: 'Finanzierung',
			financings: 'Finanzierung',
			first_10_pages: 'ersten 10 Seiten',
			fisica: 'Körperliche Person',
			for_year: 'Pro Jahr',
			forget_password: 'Ich habe das Passwort vergessen',
			forgot_password: 'Passwort vergessen',
			form: 'Form',
			form_of_payment: 'Zahlungsart',
			found: 'Gefunden',
			founds: 'Gefunden',
			friend_indicated: 'Von meinen Freunden empfohlen',
			friend_indicateds: 'Von meinen Freunden empfohlen',
			fuel: 'Kraftstoff',
			full_name: 'Vollständiger Name',
			funcPublico: 'Bist du Beamter?',
			fundamental_complete: 'Grundstufe - Vollständig',
			fundamental_incomplete: 'Grundstufe - Unvollständig',
			ganh_diario: 'Tägliche Einnahmen',
			gender: 'Geschlecht',
			grand_total: 'Gesamtsumme',
			group: 'Gruppe',
			has_withdraw_today: 'Eine Auszahlung wurde heute bereits beantragt',
			home: 'Start',
			how_it_works: 'Wie es funktioniert',
			how_to_call: 'Wie möchtest du genannt werden?',
			id_td: 'Direkte Anzeige',
			images: 'Bilder',
			in_construction: 'Bauarbeiten im Gange',
			in_order: 'In Ordnung',
			in_stock: 'Auf Lager',
			indicant: 'Indikator',
			indicated_by: 'Sie werden nominiert von',
			indicated_not_found: 'Der angegebene Indikator wurde nicht gefunden...',
			indicateds: 'Angezeigt',
			indirects: 'Indirekt',
			info: 'Newsletter',
			info_contact: 'Kontaktinformationen',
			initial: 'Initial',
			insert_new: 'Neu einfügen',
			installments: 'Raten',
			intelligence: 'Intelligenz',
			interesse: 'Was ist die Region von Interesse',
			invalid_contact: 'Ungültiges Kontaktformat',
			invalid_images: 'ungültige Bilder',
			invalid_token: 'Ungültiges Token',
			invite: 'Einladen',
			invite_friends: 'Lade deine Freunde ein',
			January: 'Januar',
			July: 'Juli',
			June: 'Juni',
			juridica: 'Juristische Person',
			know_exact_result: 'Kennst du den genauen Wert?',
			lead: 'Gemeinsam bauen wir eine neue Zukunft.',
			learn_more: 'Mehr wissen',
			left: 'Links',
			left_preferential: 'Bevorzugt die linke Seite',
			legalNature: 'Rechtsnatur',
			level: 'Eben',
			limite_excedido: 'Belastungslimit überschritten',
			linear: 'Linear',
			link_email: 'Klicken Sie auf den Link, der an die registrierte E-Mail gesendet wurde!',
			link_invite_copy: 'Teilen-Link erfolgreich kopiert',
			liquid: 'Flüssig',
			list: 'Aufführen',
			loading: 'Wird geladen',
			location: 'Standort',
			login: 'Betreten',
			logout: 'Ausgehen',
			male: 'Männlich',
			manage_users: 'Benutzer verwalten',
			manager: 'Manager',
			mandala: 'Mandala',
			mandalas: 'Mandalas',
			manufacturer: 'Hersteller',
			March: 'Marsch',
			maritalStatus: 'Familienstand',
			market: 'Markt',
			married: 'Verheiratet',
			master_complete: 'Master - Abgeschlossen',
			master_incomplete: 'Master - Unvollständig',
			May: 'Dürfen',
			message: 'Nachricht',
			messageNotSent: 'Ihre Nachricht konnte nicht gesendet werden',
			messageSent: 'Ihre Nachricht wurde erfolgreich gesendet',
			middle_complete: 'Mittel - Vollständig',
			middle_incomplete: 'Mittel - Unvollständig',
			min_amount_error: 'Der Wert muss mindestens betragen:',
			min_withdraw: 'Mindestbetrag für die Auszahlung',
			missing: 'Fehlen',
			mission: 'Mission',
			mission_vision_values: 'Mission, Vision und Werte',
			model: 'Modell',
			monitors: 'Monitore',
			monthly: 'Monatliche Bezahlung',
			motherName: 'Name der Mutter',
			movements: 'Bewegungen',
			msg_renovacao: 'Sie haben Ihr Verdienstlimit erreicht, erneuern Sie Ihr Paket.',
			my_account: 'Mein Konto',
			my_wallet: 'Mein Geldbeutel',
			mystock: 'Mein Vorrat',
			name: 'Name',
			nascMaisVelhoRenda2: 'Wie lautet das Geburtsdatum des Ältesten?',
			nationality: 'Nationalität (Geburtsland)',
			naturalness: 'Geburtsort (Stadt, in der Sie geboren wurden)',
			nature: 'Natur',
			network: 'Netzwerk',
			new: 'Neu',
			new_call: 'Neue Berufung',
			new_financing: 'Neue Finanzierung',
			new_order: 'Neue Bestellung',
			new_password: 'Neues Kennwort',
			new_password_security: 'Neues Sicherheitskennwort',
			new_seller: 'Neuer Verkäufer',
			new_wallet: 'Neues Portfolio',
			news: 'Nachricht',
			next: 'Nächste',
			nick: 'Benutzer',
			nis: 'PIS/NIS',
			nis_already_registered: 'PIS/NIS bereits registriert',
			no: 'NEIN',
			no_approve: 'immer noch nicht genehmigen',
			no_delete: 'nicht ausschließen',
			no_literate: 'nicht belesen',
			no_repeat: 'Daten können nicht wiederholt werden',
			no_results_found: 'keine Ergebnisse gefunden',
			no_services_or_products_added: 'Keine Dienstleistungen oder Produkte hinzugefügt',
			no_settings: 'Gehen Sie zu Einstellungen und geben Sie Ihre Daten ein.',
			none_friends_indicated: 'Ich habe noch keine Freunde geworben',
			not_authorized: 'Nicht berechtigt',
			not_found: 'Nicht gefunden',
			not_login: 'Die Eingabe war nicht möglich, überprüfen Sie die Daten und versuchen Sie es erneut!',
			not_orders: 'Keine Zahlungsanweisungen',
			not_permission: 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen.',
			not_registered: 'Noch nicht registriert? Klicken Sie hier und registrieren Sie sich',
			not_results: 'Keine Aufzeichnungen gefunden',
			not_self_register: 'Registrierung nicht möglich',
			not_today_entry: 'Wir haben heute noch keine Karten',
			notfound_ad: 'Keine Anzeigen gefunden',
			November: 'November',
			number: 'Nummer',
			obs: 'Kommentare',
			October: 'Oktober',
			of: 'In',
			off: 'Ausgelöscht',
			on_total: 'von allen',
			open: 'Offen',
			opening: 'Öffnung',
			operation: 'Leistung',
			ordenation: 'Ordination',
			order: 'Befehl',
			order_not_found: 'Bestellung nicht gefunden',
			orders: 'Anfragen',
			other: 'Andere',
			other_services_or_products: 'Andere Dienstleistungen oder Produkte',
			paid: 'Ausbezahlt',
			paid_date: 'Zahltag',
			partial: 'Teilweise bezahlt',
			passport: 'Reisepass',
			password: 'Passwort',
			password_security: 'Sicherheitskennwort',
			pay: 'Zahlen',
			pay_generate: 'Zahlung generieren',
			pay_selected: 'Ausgewählt bezahlen',
			pay_value_in: 'den Betrag einzahlen',
			payable: 'Zahlen',
			payer: 'Zahler',
			payment: 'Zahlung',
			ped_retirada: 'Auszahlungsantrag',
			ped_retirada_gan_diario: 'Antrag auf Auszahlung des Tagesverdienstes',
			pendent: 'Ausstehend',
			people: 'Menschen',
			person: 'Person',
			person_found_in_other_prefecture: 'Person, die bereits in einer anderen Gemeinde gemeldet ist',
			personal: 'Leute',
			personal_addresses: 'Adressen',
			personal_contacts: 'Kontakte',
			personal_data: 'persönliche Daten',
			personal_infos: 'Information',
			personal_pcva: 'Grünes und gelbes Hausprogramm',
			phone: 'Telefon',
			pix: 'PIX',
			pix_payment: 'Zahlung in PIX',
			pix_payment_return: 'Zahlungsrückgabe in PIX',
			pix_receipt: 'Empfang in PIX',
			pl_carreira: 'Karriereweg',
			plan: 'Wohnung',
			plates: 'Platten',
			please_wait: 'Warten Sie mal',
			plus: 'Plus',
			point: 'Punkt',
			points: 'Punkte',
			portfolio: 'Portfolio',
			pos_complete: 'Lato sensu - Vollständig',
			pos_incomplete: 'Lato sensu - Unvollständig',
			prefecture: 'Rathaus',
			prefecture_addresses: 'Adressen',
			prefecture_contacts: 'Kontakte',
			prefecture_data: 'Daten des Rathauses',
			prefecture_infos: 'Information',
			prefectures: 'Rathäuser',
			prev: 'Vorherige',
			price: 'Preis',
			print: 'Ausdrucken',
			privacy_policies: 'Datenschutz-Bestimmungen',
			private_works: 'Private Werke',
			profession: 'Beruf',
			profile: 'Profil',
			program: 'Programm',
			programs: 'Software',
			project: 'Projekt',
			projects: 'Projekte',
			public_works: 'Öffentliche Bauarbeiten',
			purchase: 'Kaufen',
			qualify_binary: 'Binäre Qualifikation',
			qualquer: 'Beliebig',
			quantity: 'Menge',
			quotation: 'Preis',
			read: 'Lesen',
			receivable: 'Bekommen',
			recipient: 'Empfänger',
			recommendations: 'Empfehlungen',
			redefine_password: 'Passwort neu definieren',
			register: 'Aufzeichnen',
			register_people: 'Personenregistrierung',
			register_wallet: 'Registrieren Sie eine BTC-Wallet, um Auszahlungen vorzunehmen',
			registers: 'Aufzeichnungen',
			registers_users: 'Benutzer Registration',
			reject: 'Ablehnen',
			remaining: 'Verblieben',
			remove: 'Zu entfernen',
			rendaIndividual: 'Individuelles Bruttoeinkommen',
			renew: 'Erneuern',
			renew_mandala: 'Mandala-Erneuerung',
			reply: 'Antworten',
			reports: 'Berichte',
			required_data_not_sent: 'Erforderliche Daten nicht gesendet',
			resend_email: 'Verschicke die E-Mail erneut',
			residential: 'Wohn',
			responded: 'Antwortete',
			restricao: 'Haben Sie Einschränkungen in Ihrem Namen? (SPC/Serasa)',
			result: 'Ergebnis',
			results: 'Ergebnisse',
			resume_data: 'Datenzusammenfassung',
			revoked: 'widerrufen',
			rg: 'Allgemeine Registrierung (Identität)',
			rgAgency: 'RG-Emittent',
			rgDate: 'RG-Ausgabedatum',
			rgUf: 'ID-Status',
			right: 'Rechts',
			right_preferential: 'Bevorzugen Sie die rechte Seite',
			roi: 'Tägliche Einnahmen',
			sale: 'Verkauf',
			saq_duplicado: 'Doppelte Auszahlung',
			save: 'Speichern',
			schooling: 'Ausbildung',
			search: 'Suchen',
			secretary: 'Sekretär',
			security_password: 'Sicherheitskennwort',
			see: 'Um zu sehen',
			see_advents: 'siehe Anzeigen',
			see_call: 'siehe Anruf',
			see_details: 'siehe Einzelheiten',
			see_details_wallet: 'Portfoliodetails anzeigen',
			see_financing: 'Siehe Finanzierung',
			see_order: 'Bestellung ansehen',
			see_user: 'Benutzer anzeigen',
			select_vehicle: 'Fahrzeug auswählen',
			self_register: 'Registrieren',
			seller: 'Verkäufer',
			send_call: 'Ticket senden',
			send_to: 'Senden an',
			September: 'September',
			settings: 'Einstellungen',
			share_your_link: 'Teilen Sie Ihren Link',
			show: 'sich zeigen',
			show_from: 'zeigt von',
			side: 'Seite',
			sign_in_to_start_your_session: 'Melden Sie sich an, um Ihre Sitzung zu starten',
			sign_up: 'Registrieren',
			single: 'Einzel',
			sold: 'Verkauft',
			state: 'Zustand',
			status: 'Status',
			strategies: 'Strategien',
			street: 'Straße',
			subject: 'Thema',
			submit: 'Senden',
			submit_placeholder: 'Bitte füllen Sie Ihre E-Mail aus',
			subscribe: 'Melden Sie sich an, um unsere Neuigkeiten zu erhalten',
			subtitle: 'Untertitel',
			success_update_user: 'Benutzer erfolgreich aktualisiert!',
			success_wallet: 'Portfolio erfolgreich aktualisiert!',
			suggestions: 'Vorschläge',
			superior_complete: 'Abgeschlossen',
			superior_incomplete: 'Unvollständig höher',
			supervisor: 'Aufsicht',
			support: 'Unterstützung',
			support_materials: 'Unterstützungsmaterialien',
			suspended: 'Ausgesetzt',
			symbols: 'Symbole',
			system: 'System',
			target: 'Ziel',
			tempoCarteira: 'Wie lange ist die Platte schon im Portfolio',
			terms: 'Ich akzeptiere die Nutzungsbedingungen',
			this_value_in: 'Dieser Wert in',
			title: 'Titel',
			to: 'Bis',
			to_pay: 'Ausschalten',
			to_receive: 'empfangbar',
			today_entry: 'Der heutige Eintrag',
			token_not_found_or_already_used: 'Token nicht gefunden oder bereits verwendet',
			total: 'Gesamt',
			total_entry: 'Gesamteintrag',
			traderbot: 'Bot-Händler',
			transf_credito: 'Überweisung',
			transf_debito: 'Lastschriftüberweisung',
			transfer_payment: 'Überweisung',
			transfer_payment_return: 'Überweisungsrückgabe',
			transfer_receipt: 'Quittung in Überweisung',
			transparency: 'Transparenz',
			tx_adm_cred: 'Steuern verwalten',
			tx_adm_deb: 'Steuern verwalten',
			tx_retirada: 'Abhebegebühr',
			tx_retirada_gan_diario: 'Tägliche Gewinnabhebungsrate',
			type: 'Typ',
			update: 'Aktualisieren',
			update_wallet: 'Portfolio aktualisieren',
			upgrade: 'Verbesserung',
			used: 'Gebraucht',
			user: 'Benutzer',
			user_active: 'aktiver Nutzer',
			user_already_exists: 'Benutzer existiert bereits!',
			user_and_or_password_wrong: 'Falscher Nutzername und / oder Passwort!',
			user_inactive: 'inaktiver Benutzer',
			user_not_found: 'Benutzer nicht gefunden',
			user_not_logged: 'Benutzer nicht angemeldet',
			user_or_password_wrong: 'falscher Benutzername oder Passwort',
			users: 'Benutzer',
			users_list: 'Benutzerliste',
			valid_images: 'gültige Bilder',
			valorRenda2: 'Wie hoch ist der Wert des Zweiteinkommens?',
			value: 'Wert',
			value_donate_on: 'Spendenwert in',
			value_financed: 'finanzierter Betrag',
			value_installment: 'Ratenwert',
			values: 'Werte',
			vehicle: 'Fahrzeug',
			vehicles: 'Fahrzeuge',
			view_order: 'Siehe Bestellung',
			vision: 'Vision',
			visitor: 'Besucher',
			voucher: 'Gutschein',
			wait_confirm: 'Warte auf Bestätigung',
			waiting: 'Warten',
			waiting_email:
				'Die Registrierung wurde erfolgreich durchgeführt, jetzt muss nur noch die E-Mail bestätigt werden.',
			waiting_payment: 'Warten auf Zahlung',
			wallet: 'Portfolio',
			wallet_generate: 'Portfolio erstellen',
			wallets: 'Geldbörsen',
			want_donate: 'Ich möchte spenden',
			want_now_more_about: 'Ich möchte mehr darüber wissen',
			warning: 'AUFMERKSAMKEIT',
			we_are: 'Sind',
			webhooks: 'Webhooks',
			welcome: 'Willkommen',
			who_we_are: 'Wer wir sind',
			who_we_are_subtitle: 'Eine kurze Beschreibung über die',
			widower: 'Witwer',
			will_expire_in: 'verfällt in',
			with: 'mit',
			withdraw: 'Rückzug',
			withdraw_sended_email:
				'Auszahlung angefordert, überprüfen Sie Ihre E-Mail und klicken Sie auf den Link zur Autorisierung!',
			withdrawal: 'Abhebungen',
			withdrawals: 'Abhebungen',
			year: 'Jahr',
			years: 'Jahre',
			yes: 'Ja',
			your_country: 'Dein Land',
			your_dashboard: 'Dies ist Ihre Systemsteuerung.',
			your_link: 'Dein Link',
			zipcode: 'PLZ',
			amount_above_balance: 'Mangelhaftes Gleichgewicht',
			symbol: 'Symbol',
			close: 'Schließung',
			high: 'Alt',
			low: 'Niedrig',
			order_book: 'Auftragsbuch',
			coin: 'Münze',
			free: 'Buch',
			locked: 'verstopft',
			you_have: 'Du hast',
			buy_volume: 'Volumen kaufen',
			sell_volume: 'Volumen verkaufen',
			estimate: 'Geschätzt',
			photo: 'Foto',
			telegram_chat_id: 'Telegram Chat ID',
			pendent_order: 'Sie haben anhängige Bestellungen',
			active_automations: 'Aktive Automatisierung',
			active_monitors: 'Aktive Monitore',
			active_users: 'Aktive Benutzer',
			active_connections: 'Aktive Verbindungen',
			ticker_health: 'Ticker Gesundheit',
			book_health: 'Gesundheit buchen',
			enter_your_new_password: 'Gib dein neues Passwort ein',
			confirm_your_new_password: 'Geben Sie Ihr neues Passwort erneut ein',
			alerts: 'Warnung',
			alert: 'Alarm',
			telegram: 'Telegram',
			logs: 'Protokolle',
			release_withdrawals: 'Abhebungen veröffentlichen',
			bot_active: 'Bot zulassen',
			available: 'Verfügbar',
		},
	},
};

export { messages };
